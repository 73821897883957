import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState, useEffect } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import Footer from 'src/components/Footer';
import axios from 'axios';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPencil } from "react-icons/fa6";
import { log } from 'console';

interface Data {
  username: string;
  pass: string;
  displayname: string;
  mobile: string;
  email: string;
  isactive: string;
  cby: string;
  con: Date;
  mby: string;
  mon: Date;
  id: number;
  roleid: number;
}

interface Role {
  id: number;
  role: string;
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

function User() {
  const [data, setData] = useState<Data[]>([]);
  const [role, setRole] = useState<Role[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formData, setFormData] = useState<any>({
    id: '',
    username: '',
    pass: '',
    displayname: '',
    mobile: '',
    email: '',
    isactive: '',
    cby: '',
    con: '',
    mby: '',
    mon: '',
    roleid: '' 
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    apiService.getUser(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_role").then(response => {
      setRole(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [rowsPerPage, page, formData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only)
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };
   const validateEmail = (email : string) => {
    const emailRegex = /^[^\s@]+@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io)$/;
    return emailRegex.test(email);
   };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
     
    if (name === 'mobile') {
      setIsMobileValid(validateMobile(value));
    }
    
    if(name == 'email'){
      setIsEmailValid(validateEmail(value));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      username: '',
      pass: '',
      displayname: '',
      mobile: '',
      email: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: '',
      roleid: ''
    });
    setSelectedItem(null);
    setIsMobileValid(true);
    setIsEmailValid(true);
  };

  // Insert and update
  let submit = (event: any) => {
    event.preventDefault();

    // Insert
    if (selectedItem == null) {
      let data = {
        username: formData?.username,
        pass: formData?.pass,
        displayname: formData?.displayname,
        mobile: formData?.mobile,
        email: formData?.email,
        isactive: formData?.isactive,
        cby: "1",
        roleid: formData?.roleid
      };
      
      apiService.insertUser(data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            username: '',
            pass: '',
            displayname: '',
            mobile: '',
            email: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: '',
            roleid: ''
          });
          handleClose(); 
        }
      }).catch((err) => {
        console.log("err", err);
      });
    } 
    // Update
    else {
      let data = {
        id: formData?.id,
        username: formData?.username,
        pass: formData?.pass,
        displayname: formData?.displayname,
        mobile: formData?.mobile,
        email: formData?.email,
        isactive: formData?.isactive,
        roleid: formData?.roleid,
        cby: "1"    
      };
      
      apiService.updateUser(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            username: '',
            pass: '',
            displayname: '',
            mobile: '',
            email: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: '',
            roleid: ''
          });
          handleClose(); 
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  };

  const handleEditClick = (item: Data) => {

    apiService.getUser_id(item?.id).then(response => {
  console.log(response,"response_id");

  setFormData({
    id: response.data?.id.toString(),
    username: response.data?.username,
    pass: response.data?.pass,
    displayname: response.data?.displayname,
    mobile: response.data?.mobile,
    email: response.data?.email,
    isactive: response.data?.isactive,
    cby: response.data?.cby,
    con: response.data?.con,
    mby: response.data?.mby,
    mon: response.data?.mon,
    roleid: response.data?.roleid
  });
  setSelectedItem(item);
  
    }).catch((err) => {
        console.log("err", err);
      });
   
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>User - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="User"
          subHeading=""
        />
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // alignItems: 'flex-end',
            mt: -3.5, // Add some margin-bottom for spacing
          }}
        >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add User
      </Button></Box>
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add User
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Display Name</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Email Id</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.displayname}</TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                        <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? "Edit User" : "Add User"}</DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="dense"
                id="username"
                label="UserName"
                name='username'
                fullWidth
                value={formData?.username}
                onChange={handleChange}
              />
              <TextField
                required
                margin="dense"
                id="pass"
                label="Password"
                name='pass'
                fullWidth
                value={formData?.pass}
                onChange={handleChange}
              />
              <TextField
                required
                margin="dense"
                id="displayname"
                label="Display Name"
                name='displayname'
                fullWidth
                value={formData?.displayname}
                onChange={handleChange}
              />
              <TextField
                required
                margin="dense"
                id="mobile"
                label="Mobile No"
                name='mobile'
                fullWidth
                value={formData?.mobile}
                onChange={handleChange}
                error={!isMobileValid}
                helperText={!isMobileValid ? 'Invalid mobile number' : ''}
              />
              <TextField
                required
                margin="dense"
                id="email"
                label="Email Id"
                name='email'
                fullWidth
                value={formData?.email}
                onChange={handleChange}
                error={!isEmailValid}
                helperText={!isEmailValid ? 'Invalid email id' : ''}
              />
              <TextField
                select
                margin="dense"
                label="Status"
                name='isactive'
                fullWidth
                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                onChange={handleChange}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                margin="dense"
                label="User Role"
                name='roleid'
                fullWidth
                value={formData?.roleid}
                onChange={handleChange}
              >
                {role.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.role} 
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid}>
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />
    </>
  );
}

export default User;
