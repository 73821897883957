import { Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";

interface Data {
  id: number;
  empno: string;
  name: string;
  compid: number;
  compname: string;
  deptid: number;
  deptname: string;
  designid: number;
  plantid: number;
  Omobile: string;
  Pmobile: string;
  Oemail: string;
  Pemail: string;
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  add5: string;
  cadd1: string;
  cadd2: string;
  cadd3: string;
  cadd4: string;
  cadd5: string;
  img: string;
  isactive: string;
  cby: string;
  con: Date;
  mby: string;
  mon: Date;
}
const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

interface Comp {
  id: number;
  name: string;
}

interface Dept {
  id: number;
  name: string;
}

interface Design {
  id: number;
  name: string;
}

interface Plant {
  id: number;
  name: string;
}

function Employee() {
  const [data, setData] = useState<Data[]>([]);
  const [comp, setComp] = useState<Comp[]>([]);
  const [dept, setDept] = useState<Dept[]>([]);
  const [design, setDesign] = useState<Design[]>([]);
  const [plant, setPlant] = useState<Plant[]>([]);
  const [selectedRowdet, setSelectedRowdet] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formData, setFormData] = useState<any>({
    id: '',
    empno: '',
    name: '',
    compid: '',
    deptid: '',
    designid: '',
    plantid: '',
    Omobile: '',
    Pmobile: '',
    Oemail: '',
    Pemail: '',
    add1: '',
    add2: '',
    add3: '',
    add4: '',
    add5: '',
    cadd1: '',
    cadd2: '',
    cadd3: '',
    cadd4: '',
    cadd5: '',
    img: '',
    isactive: '',
    cby: '',
    con: '',
    mby: '',
    mon: ''
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMobileValid1, setIsMobileValid1] = useState(true);
  const [isEmailValid1, setIsEmailValid1] = useState(true);


  useEffect(() => {
    apiService.getEmp(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_company").then(response => {
      setComp(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_dept").then(response => {
      setDept(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_design").then(response => {
      setDesign(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_plant").then(response => {
      setPlant(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [rowsPerPage, page, formData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const validateMobile = (omobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{10}$/;

    return mobileRegex.test(omobile);

  };
  const validateEmail = (oemail: string) => {
    const emailRegex = /^[^\s@]+@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
    return emailRegex.test(oemail);
  };

  const validateMobile1 = (pmobile: string) => {
    // Mobile number validation logic (example: 10 digits only)
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(pmobile);

  };
  const validateEmail1 = (pemail: string) => {
    const emailRegex = /^[^\s@]+@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
    return emailRegex.test(pemail);
  };

  const validateForm = () => {
    // Add your validation logic here
    const requiredFields = [
      'omobile', 'pmobile'
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;


    if (name === 'omobile') {
      setIsMobileValid(validateMobile(value));
    }

    if (name == 'oemail') {
      setIsEmailValid(validateEmail(value));
    }
    if (name === 'pmobile') {
      setIsMobileValid1(validateMobile1(value));
    }

    if (name == 'pemail') {
      setIsEmailValid1(validateEmail1(value));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    if (name === "img" && event.target.files) {
      const file = event.target.files[0];
      const formdata = new FormData();
      formdata.append('file', file);

      apiService.uploadFiles(formdata)
        .then(response => {
          const url = response.data; // Assuming response.data contains the file URL

          // Update the formData with the file URL
          setFormData(prevFormData => ({
            ...prevFormData,
            img: url,
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      empno: '',
      name: '',
      compid: '',
      deptid: '',
      designid: '',
      plantid: '',
      omobile: '',
      pmobile: '',
      oemail: '',
      pemail: '',
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      add5: '',
      cadd1: '',
      cadd2: '',
      cadd3: '',
      cadd4: '',
      cadd5: '',
      img: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: ''
    });
    setSelectedItem(null);
    setIsMobileValid(true);
    setIsEmailValid(true);
    setIsMobileValid1(true);
    setIsEmailValid1(true);
  };


  // Insert and update
  let submit = (event: any) => {
    event.preventDefault();

    if (!validateForm()) {
      console.log('Form is invalid. Please fill all required fields.');
      return;
    }

    // Insert
    if (selectedItem == null) {
      let data = {
        empno: formData?.empno,
        name: formData?.name,
        compid: formData?.compid,
        deptid: formData?.deptid,
        designid: formData?.designid,
        plantid: formData?.plantid,
        omobile: formData?.omobile,
        pmobile: formData?.pmobile,
        oemail: formData?.oemail,
        pemail: formData?.pemail,
        add1: formData?.add1,
        add2: formData?.add2,
        add3: formData?.add3,
        add4: formData?.add4,
        add5: formData?.add5,
        cadd1: formData?.cadd1,
        cadd2: formData?.cadd2,
        cadd3: formData?.cadd3,
        cadd4: formData?.cadd4,
        cadd5: formData?.cadd5,
        img: formData?.img,
        isactive: formData?.isactive,
        cby: "1"
      };

      apiService.insertEmp(data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            empno: '',
            name: '',
            compid: '',
            deptid: '',
            designid: '',
            plantid: '',
            omobile: '',
            pmobile: '',
            oemail: '',
            pemail: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            img: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''
          });
          handleClose();

        }
      }).catch((err) => {
        console.log("err", err);
      });

    }

    // Update
    else {
      let data = {
        empno: formData?.empno,
        name: formData?.name,
        compid: formData?.compid,
        deptid: formData?.deptid,
        designid: formData?.designid,
        plantid: formData?.plantid,
        omobile: formData?.omobile,
        pmobile: formData?.pmobile,
        oemail: formData?.oemail,
        pemail: formData?.pemail,
        add1: formData?.add1,
        add2: formData?.add2,
        add3: formData?.add3,
        add4: formData?.add4,
        add5: formData?.add5,
        cadd1: formData?.cadd1,
        cadd2: formData?.cadd2,
        cadd3: formData?.cadd3,
        cadd4: formData?.cadd4,
        cadd5: formData?.cadd5,
        img: formData?.img,
        isactive: formData?.isactive,
        cby: "1"
      };
      apiService.updateEmp(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            empno: '',
            name: '',
            compid: '',
            deptid: '',
            designid: '',
            plantid: '',
            omobile: '',
            pmobile: '',
            oemail: '',
            pemail: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            img: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  };
  const handleEditClick = (item: Data) => {
    // let isa = item?.isactive == "true"? 1 : 0;
    console.log(item);

    setFormData({
      id: item?.id.toString(),
      empno: item?.empno,
      name: item?.name,
      compid: item?.compid,
      deptid: item?.deptid,
      designid: item?.designid,
      plantid: item?.plantid,
      omobile: item?.Omobile,
      pmobile: item?.Pmobile,
      oemail: item?.Oemail,
      pemail: item?.Pemail,
      add1: item?.add1,
      add2: item?.add2,
      add3: item?.add3,
      add4: item?.add4,
      add5: item?.add5,
      cadd1: item?.cadd1,
      cadd2: item?.cadd2,
      cadd3: item?.cadd3,
      cadd4: item?.cadd4,
      cadd5: item?.cadd5,
      img: item?.img,
      // isactive: item?.isactive == "true"? 1 : 0,
      isactive: item?.isactive,
      cby: item?.cby,
      con: item?.con,
      mby: item?.mby,
      mon: item?.mon
    });
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Employee - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="Employee"
          subHeading=""
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // alignItems: 'flex-end',
            mt: -3.5, // Add some margin-bottom for spacing
          }}
        >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New
          </Button></Box>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Employee Info Section */}
                <Grid item xs={12} >
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Employee Info</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="empno"
                    label="Employee Number"
                    name='empno'
                    value={formData?.empno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="name"
                    label="Name"
                    name='name'
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Company"
                    name='compid'
                    fullWidth
                    value={formData?.compid}
                    onChange={handleChange}
                  >
                    {comp.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Department"
                    name='deptid'
                    fullWidth
                    value={formData?.deptid}
                    onChange={handleChange}
                  >
                    {dept.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Designation"
                    name='designid'
                    fullWidth
                    value={formData?.designid}
                    onChange={handleChange}
                  >
                    {design.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Plant"
                    name='plantid'
                    fullWidth
                    value={formData?.plantid}
                    onChange={handleChange}
                  >
                    {plant.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Contact Info Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Contact Info</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField

                    id="omobile"
                    label="Official Mobile No"
                    name='omobile'
                    value={formData?.omobile}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isMobileValid}
                    helperText={!isMobileValid ? 'Invalid mobile number' : ''}

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="pmobile"
                    label="Personal Mobile No"
                    name='pmobile'
                    value={formData?.pmobile}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isMobileValid1}
                    helperText={!isMobileValid1 ? 'Invalid mobile number' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="oemail"
                    label="Official Email"
                    name='oemail'
                    value={formData?.oemail}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isEmailValid}
                    helperText={!isEmailValid ? 'Invalid email id' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="pemail"
                    label="Personal Email"
                    name='pemail'
                    value={formData?.pemail}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isEmailValid1}
                    helperText={!isEmailValid1 ? 'Invalid email id' : ''}
                  />
                </Grid>

                {/* Permanent Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Permanent Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add1"
                    label="Flat No/House No"
                    name='add1'
                    value={formData?.add1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add2"
                    label="Street Address"
                    name='add2'
                    value={formData?.add2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add3"
                    label="Landmark"
                    name='add3'
                    value={formData?.add3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add4"
                    label="Region/City"
                    name='add4'
                    value={formData?.add4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add5"
                    label="State"
                    name='add5'
                    value={formData?.add5}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>

                {/* Current Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Current Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd1"
                    label="Flat No/House No"
                    name='cadd1'
                    value={formData?.cadd1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd2"
                    label="Street Address"
                    name='cadd2'
                    value={formData?.cadd2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd3"
                    label="Landmark"
                    name='cadd3'
                    value={formData?.cadd3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd4"
                    label="Region/City"
                    name='cadd4'
                    value={formData?.cadd4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd5"
                    label="State"
                    name='cadd5'
                    value={formData?.cadd5}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <input
                        type="file"
                        name="img"
                        id="img"
                        onChange={handleFileChange}
                        className="fileInput"
                      />
                      {/* Display the URL as a clickable link */}
                      {formData.img && (
                        <a href={formData.img} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      )}

                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <TextField
                        select
                        margin="dense"
                        label="Status"
                        name='isactive'
                        fullWidth
                        value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                        onChange={handleChange}
                      >
                        {status.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid || !isMobileValid1 || !isEmailValid1}>
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Employee No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                      {/* Add more headers as necessary */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.empno}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.compname}</TableCell>
                        <TableCell>{row.deptname}</TableCell>
                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                        {/* Add more cells as necessary */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data.length === 0 ? 0 : data[0]["count"]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Footer />
    </>
  );
}

export default Employee;
