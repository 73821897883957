import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import City from './admin/city';
// import { Home } from '@mui/icons-material';
import User from './admin/user';
import Priority from './crm/priority';
import Status from './crm/status';

import FollowType from './crm/followtype';
import Role from './crm/role';
import Mode from './crm/mode';
import Type from './crm/type';
import Employee from './admin/employee';
import Division from './crm/division';
// import DesignDev from './design/designdev';
import UpdatePage from './design/updatepage';



import { Home } from '@mui/icons-material';
import HomePage from './layouts/home/home';

import Designation from './admin/designation';
import Company from './admin/company';
import Plant from './admin/plant';
import Team from './crm/team';
import Country from './admin/country';
import State from './admin/state';
import District from './admin/district';


import Department from './admin/department';
import QuoteTerms from './crm/quoteterms';
import AddEnquiry from './crm/addenquiry';
import UpdateEnquiry from './crm/updateenquiry';
import EnquiryDetail from './crm/enquiry';
import Enquiry from './crm/enquiry';
import Quotation from './crm/Quotation';
import DashboardCrypto from 'src/content/dashboards/Crypto';
import WatchList from './content/dashboards/Crypto/WatchList';
import HomeScreen from './crm/Home';
import FinancialYear from './admin/financialyear';
import Customer from './crm/customer';
import Target from './crm/target';
import DailyFollowupReport from './crm/dailyfollowreport';
import DesignDev from './design/designdev';
import UpdateQuotation from './crm/updatequotation';
import BudgetQuotation from './crm/BudgetQuotation';
import EssentialData from './crm/essential';
import Tax from './admin/tax';
import MisDashboard from './crm/MISDashboard';
import EnqStsReport from './crm/enquirystatuswisereport';

import MonthlyvsActual from './crm/Monthlywisereport';
import Sidebar from './layouts/SidebarLayout/sidebar1';
// import MisDashboards from './admin/misdashboard';
import EnquiryTable from './crm/customerdetail';
import UpdateLead from './lead/updatelead';
import AddLead from './lead/addlead';
import CrmDashboard from './crm/crmdashboard';
import EditQuotation from './crm/editquotation';



const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: '/SuperAdmin/UserSettings',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'City',
        element: <City />
      },
      {
        path: 'User',
        element: <User />
      },
      {
        path: 'Designation',
        element: <Designation />
      },
      {
        path: 'country',
        element: <Country />
      },
      {
        path: 'state',
        element: <State />
      },
      {
        path: 'district',
        element: <District />
      },
       {
        path: 'Department',
        element: <Department />
      },
      {
        path: 'Employee',
        element: <Employee />
      }
      ,
      {
        path: 'UserRole',
        element: <Role />
      }
      // {
      //   path: 'misdashboards',
      //   element: <MisDashboards/>
      // }

    ]
  },
  {
    path: '/SuperAdmin/CompanyRegister',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'Company',
        element: <Company />
      },
      {
        path: 'Plant',
        element: <Plant />
      },
      {
        path: 'financialyear',
        element: <FinancialYear />
      },
      {
        path: 'tax',
        element: <Tax />
      },
      // {
      //   path: 'misdashboards',
      //   element: <MisDashboards/>
      // }

    ]
  },
  //CRM
  {
    path: '/MarketingCRM/GeneralSettings',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'Priority',
        element: <Priority />
      },
      {
        path: 'Status',
        element: <Status />
      },
      {
        path: 'FollowType',
        element: <FollowType />
      },
      {
        path: 'ModeOfEnquiry',
        element: <Mode />
      },
      {
        path: 'Type',
        element: <Type />
      },
      {
        path: 'Team',
        element: <Team />
      },
      {
        path: 'QuoteTerms',
        element: <QuoteTerms />
      },
      {
        path: 'Target',
        element: <Target />
      },
      {
        path: 'EssentialData',
        element: <EssentialData />
      }
    ]
  },
  {
    path: '/MarketingCRM',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'LeadEnquiry',
        element: <Enquiry />
      },
      {
        path: 'AddEnquiry',
        element: <AddEnquiry />
      },
      {
        path: 'AddLead',
        element: <AddLead />
      },
      {
        path: 'UpdateEnquiry',
        element: <UpdateEnquiry />
      },
      {
        path: 'Quotation',
        element: <Quotation />
      },
      {
        path: 'BudgetQuotationPending',
        element: <BudgetQuotation />
      },
      {
        path: 'UpdateQuotationPending',
        element: <UpdateQuotation />
      },
      {
        path: 'EditQuotation',
        element: <EditQuotation />
      },
      {
        path: 'Customer',
        element: <Customer />
      },
      {
        path: 'home',
        element: <HomeScreen />
      },
      {
        path: 'CRMDashboard',
        element: <CrmDashboard />
      },
    ]
  },
  {
    path: '/MarketingCRM/Reports',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'DailyFollowupReport',
        element: <DailyFollowupReport />
      },
      {
        path: 'EnquiryStatusWiseReport',
        element: <EnqStsReport />
      },
      {
        path: 'MISDashboard',
        element: <MisDashboard />
      },

      {
        path: 'MonthlyVsActual',
        element: <MonthlyvsActual />
      },
      {
        path: 'CustomerDetail',
        element: <EnquiryTable />
      },
      // {
      //   path: 'crmdashboard',
      //   element: <CrmDashboard />
      // },


    ]
  },
  //Design
  {
    path: '/Design',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="enquiry" replace />
      },
      {
        path: 'DesignFollowup',
        element: <DesignDev />
      },
      {
        path: 'UpdateDesign',
        element: <UpdatePage />
      }



    ]
  },

  {
    path: '/home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'homepage',
        element: <HomePage />
      },
      {
        path: 'sidebar',
        element: <Sidebar />
      }

    ]
  },
  {
  path: '/lead',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="buttons" replace />
    },
    {
      path: 'addlead',
      element: <AddLead />
    },
   
   
    {
      path: 'updatelead',
      element: <UpdateLead />
    }
  ]
}
];
export default routes;
