// import axios from 'axios';

// axios.interceptors.request.use(

//   config => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

import axios from 'axios';

// Base URL for API requests
  // const API_URL = 'https://localhost:7202/api/';  // Change this to the production URL when needed
 const API_URL = 'https://saarcapi.netkampus.in/api/';
 // const API_URL = 'https://crmapi.netkampus.in/api/';


// Create an Axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,  // If using cookies or handling CORS
});

// Request interceptor to add the token to every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
