import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, MenuItem, Grid, useMediaQuery, useTheme, Checkbox } from '@mui/material';
import apiService from 'src/services/api-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { successToast } from 'src/layouts/utile/toast';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';

const formatDateToYYYYMMDD = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const day = String(d.getDate()).padStart(2, '0'); // Ensure 2 digits
  return `${year}-${month}-${day}`;
};

interface FollowDataItem {

  id: number;
  fdate: Date; // Change from string to Date
  ftypeid: number;
  priority: string;
  fby: number;
  desp: string;
  nfdate: Date; // Change from string to Date
  statusid: number;
  ftypename: string;
  fbyname: string;
  statusname: string;
  fdate_s: string;
  nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EnqQuote {
  id: number;
  qdate: Date;          // Date object for date values
  qdate_s: string;      // String representation of date, if needed
  qno: string;          // Quote number
  rno: number;          // Reference number
  qval: number;         // Quote value
  cusref: string;       // Customer reference
  cuspocname: string;  // Customer Point of Contact name
  cuspocno: string;    // Customer Point of Contact number
  cusdesign: string;   // Customer design
  subject: string;     // Subject of the quote
}

interface EssentialTableRow {
  enqdetid: number;
  essdataid: number;
  compliance: string;
  attach: string;
}

interface TableRowData {
  id: number;
  enqdetid: number | null;  // Null or number depending on the data
  desp: string;
  mpnno: string;     // Null or number depending on the data
  qty: number;
  essentialTableRows: EssentialTableRow[];
  unitPrice?: number; // Add this if it is part of your data
  taxableAmount?: number; // Add this if it is part of your data
  igst?: number;
}

interface FormData {
  tableRows: TableRowData[];
  enqfollow: FollowDataItem[];
  enqquote: EnqQuote[];
  enqNo: string,
  enqdate: string,
  updcheck: boolean;
  finYearId: number;
  lenessrow: number | null;
  empId: number;
  enqID: number | null;
  typeID: number;
  newEx: string;
  modeId: number;
  divId: number;
  rfxEnqNo: number | null;
  dueOn: Date;
  statusId: number;
  desp: string;
  basicValue: number;
  ccperson: string;
  cdesign: string;
  cmobile: string;
  cmail: string;
  tcperson: string;
  tdesign: string;
  tmobile: string;
  tmail: string;
  bankName: string;
  branchname: string;
  accNo: string;
  ifsc: string;
  isExcus: boolean;
  excusid: number | null;
  sname: string;
  code: string;
  name: string;
  city: string;
  ceo: string;
  md: string;
  mp: string;
  caddr1: string;
  caddr2: string;
  caddr3: string;
  caddr4: string;
  caddr5: string;
  caddr6: string;
  caddr7: string;
  faddr1: string;
  faddr2: string;
  faddr3: string;
  faddr4: string;
  faddr5: string;
  faddr6: string;
  faddr7: string;
  panno: string;
  tinno: string;
  email: string;
  omobile: string;
  pmobile: string;
  gstno: string;
  nbus: string;
  ncomp: string;
  datestart: Date | null;
  cpname: string;
  cpdesign: string;
  cpmobile: string;
  cpmail: string;
  annualturnover: number;
  buildOR: string;
  followtype: string;
  followdesc: string;
  isqul: boolean | null;
  nextfollow: string;
  priority: string;
  nfteamid: number | null;
  nfempid: number | null;
  attch: string;
}
interface GST {
  id: number;
  name: string;
  per: number;
}

interface LocationState1 {
  id: string;
}

const Quotation: React.FC = () => {

  const location = useLocation();
  const state = location.state as LocationState1;

  const [companyData, setCompanyData] = useState<any[]>([]); // Assuming companyData will be an array of objects
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [term, setTerm] = useState([])
  const [term1, setTerm1] = useState([])
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const defaultTextValues = [
    "Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.",
    "Looking forward to your valuable purchase order to serve you on time."
  ];
  const [freightPercentage, setFreightPercentage] = useState(0);
  const [packPercentage, setPackPercentage] = useState(0);
  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [dataToUpdate1, setDataToUpdate1] = useState([]);
  const [formData, setFormData] = useState<FormData>({
    tableRows: [
      {
        id: 0,
        enqdetid: null,
        desp: "",
        mpnno: '',
        qty: 0,
        unitPrice: 0,
        taxableAmount: 0,
        essentialTableRows: [
          {
            enqdetid: 0,
            essdataid: 0,
            compliance: "",
            attach: ""
          }
        ]
      },
    ],
    enqfollow: [
      {
        id: 0,
        fdate: new Date(),
        ftypeid: 0,
        priority: "",
        fby: 0,
        desp: "",
        nfdate: new Date(),
        statusid: 0,
        ftypename: "",
        fbyname: "",
        statusname: "",
        fdate_s: "",
        nfdate_s: ""
      }
    ],
    enqquote: [
      {
        id: 0,
        qdate: new Date(),
        qdate_s: "",
        qno: "",
        rno: 0,
        qval: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        subject: ""
      }
    ],
    updcheck: false,
    finYearId: 0,
    lenessrow: null,
    enqNo: '',
    enqdate: '',
    empId: 0,
    enqID: 0,
    typeID: 0,
    newEx: "",
    modeId: 0,
    divId: 0,
    rfxEnqNo: null,
    dueOn: new Date(),
    statusId: 0,
    desp: "",
    basicValue: 0,
    ccperson: "",
    cdesign: "",
    cmobile: "",
    cmail: "",
    tcperson: "",
    tdesign: "",
    tmobile: "",
    tmail: "",
    bankName: "",
    branchname: "",
    accNo: "",
    ifsc: "",
    isExcus: false,
    excusid: null,
    sname: "",
    code: "",
    name: "",
    city: "",
    ceo: "",
    md: "",
    mp: "",
    caddr1: "",
    caddr2: "",
    caddr3: "",
    caddr4: "",
    caddr5: "",
    caddr6: "",
    caddr7: "",
    faddr1: "",
    faddr2: "",
    faddr3: "",
    faddr4: "",
    faddr5: "",
    faddr6: "",
    faddr7: "",
    panno: "",
    tinno: "",
    email: "",
    omobile: "",
    pmobile: "",
    gstno: "",
    nbus: "",
    ncomp: "",
    datestart: null,
    cpname: "",
    cpdesign: "",
    cpmobile: "",
    cpmail: "",
    annualturnover: 0,
    buildOR: "",
    followtype: "",
    followdesc: "",
    isqul: null,
    nextfollow: "",
    priority: "",
    nfteamid: null,
    nfempid: null,
    attch: ""
  });


  const [quoteData, setQuoteData] = useState({
    enqquotedet: formData.tableRows.map(row => ({
      enqdetid: row.id,
      desp: row.desp,
      mpnno: row.mpnno,
      qty: Number(row.qty), // Ensure qty is a number
      unitprice: row.unitPrice,
      taxableamt: row.taxableAmount,
      taxid: 0,
      taxamt: 0,
      total: 0
    })),
    enqquoteTerms: [],
    query: '',
    qdate: formData.enqquote && formData.enqquote.length > 0
      ? formData.enqquote[0].qdate
      : new Date(), // Provide a default value
    enqid: state.id,

    cusref: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusref : '',
    cuspocname: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocname : '',
    cuspocno: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocno : '',
    cusdesign: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusdesign : '',
    qno: 0,
    status: 'Approval pending',
    qval: 0,
    lastqid: 0,
    subject: formData.enqquote && formData.enqquote.length > 0 && formData.enqquote[0].subject
      ? formData.enqquote[0].subject
      : `Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.
Looking forward to your valuable purchase order to serve you on time.`,
    cby: "1",
    taxid: 1,
    taxamt: 0,
    packchange: 0,
    freight: 0,
    total: 0
  });

  useEffect(() => {
    if (term && term.length > 0) {
      const filteredTerms = term
        .filter(item => item.type === "0" || item.type === "1")
        .map(item => ({
          id: 0,       // Set id appropriately
          qid: 0,      // Set qid appropriately
          type: item.type,
          termId: item.id,
          name: item.name,
          checked: true
        }));
  
      const checkTerms = filteredTerms.filter(item => item.checked);
  
      setQuoteData(prevQuoteData => ({
        ...prevQuoteData,
        enqquoteTerms: checkTerms, // Ensuring it's a flat array
      }));
    }
  }, [term]);
  

  // useEffect(() => {
  //   const filteredTerms1 = term.filter(item => item.type === "1" ).map(item => ({
  //     id: 0,       // or generate an appropriate id if needed
  //     qid: 0,      // or assign the proper qid if available
  //     termId: item.id,
  //     name: item.name,
  //     checked: true
  //   }));

  //   const checkTerms1 = filteredTerms1.filter(item => item.checked === true).map(item => ({
  //     id: 0,       // or generate an appropriate id if needed
  //     qid: 0,      // or assign the proper qid if available
  //     termId: item.id,
  //     name: item.name

  //   }));

  //   setQuoteData(prevQuoteData => ({
  //     ...prevQuoteData,
  //     enqquoteTerms: checkTerms1,
  //   }));
  // }, [term]);

  // Ensure you have a way to set the item field
  const handleItemChange = (event) => {
    setQuoteData(prevData => ({
      ...prevData,
      item: event.target.value
    }));
  };



  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [currentDate] = useState(getCurrentDate());
  const [gst, setGst] = useState<GST[]>([]);


  useEffect(() => {
    apiService.getDropdown("mas_company").then(response => {
      setCompanyData(response.data?.map((data: any) => ({
        id: data?.id,
        name: data?.name,
        sname: data?.sname,
        mobile: data?.mobile,
        phone: data?.phone,
        email: data?.email,
        pan: data?.pan,
        gstno: data?.gstno,
        tinno: data?.tinno,
        hsncode: data?.hsncode,
        gemsellerid: data?.gemsellerid,
        add1: data?.add1,
        add2: data?.add2,
        add3: data?.add3,
        add4: data?.add4,
        add5: data?.add5,
        mon: data?.mon
      })));
    }).catch(err => {
      console.log("err", err);
    });
  }, []);

  useEffect(() => {
    apiService.getDropdown("crm_mas_quoteterms")
      .then(response => {
        setTerm(response.data);
        console.log("setTerm", response.data);
      })
      .catch(err => {
        console.log("err", err);
      });
    apiService.getDropdown("crm_mas_tax").then(response => {
      setGst(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, []);



  useEffect(() => {
    console.log(state.id, "Enqid");
    if (state.id) {
      // Fetch data if id is provided (for updating an enquiry)
      apiService.getquoteId(Number(state.id))
        .then(response => {
          const data = response.data;
          console.log("response.getquoteId", data);

          // Update the formData state with the fetched data
          setFormData(data as FormData);
          console.log("formData", formData);
          console.log("response.Quotation up", data);
        })
        .catch(error => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [state.id]);

  useEffect(() => {
    if (companyData.length > 0) {
      setSelectedCompany(companyData[0]);
    }
  }, [companyData]);

  const [igst, setIgst] = useState<number>(0);


  useEffect(() => {
    const totalAmount = formData.tableRows.reduce((sum, row) => sum + (row.taxableAmount || 0), 0);
    const finalTotal = totalAmount + igst;

    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      enqquotedet: formData.tableRows.map(row => ({
        enqdetid: row.id,
        desp: row.desp,
        mpnno: row.mpnno,
        qty: row.qty,
        unitprice: row.unitPrice,
        taxableamt: row.taxableAmount,
        taxid: 0,
        taxamt: igst,
        total: 0
      })),
      qval: totalAmount,
      taxamt: 0,
      packchange: 0,
      freight: 0,
      total: finalTotal
    }));
  }, [formData, igst]);


  const calculateTotals = () => {
    let qval = 0;
    let taxamt = 0;

    // Calculate qval (sum of taxable amounts)
    quoteData.enqquotedet.forEach((item) => {
      qval += item.taxableamt || 0;
      taxamt += item.taxamt || 0;
    });

    // Calculate taxamt, packchange, and freight percentages based on qval
    const packchange = (qval * (quoteData.packchange / 100)) || 0;
    const freight = (qval * (quoteData.freight / 100)) || 0;

    // Calculate total
    const total = qval + packchange + freight + taxamt;

    setQuoteData({ ...quoteData, qval, packchange, freight, taxamt, total });
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes to the previous screen without refreshing
  };
  // useEffect(() => {
  //   console.log("shouldFetchData check");

  //   if (shouldFetchData) {
  //     const dataToUpdate = quoteData;
  //     console.log("Quotation  quoteData = >", dataToUpdate);
  //     setShouldFetchData(false)
  //   }
  // }, [shouldFetchData]);

  const handleSubmit = async () => {
    try {
      console.log("Quotation quoteData", quoteData);
      const dataToUpdate = quoteData;
      console.log("Quotation quoteData", dataToUpdate);
    const response = await apiService.getQuote(dataToUpdate);

      setShouldFetchData(true);
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving quote:', error);
    }
  };

  // useEffect(() => {
  //   if (shouldFetchData) {
  //     apiService.getquoterow({})
  //       .then(response => {
  //         setTerm1(response.data);  
  //         console.log("response.data==>", response.data);
  //       })
  //       .catch(err => console.error('Error fetching data:', err))
  //       .finally(() => {
  //         setShouldFetchData(false);  // Reset the fetch state after the data is fetched
  //       });
  //   }
  // }, [shouldFetchData]);

  useEffect(() => {
    if (shouldFetchData) {
      // Assuming quoteData is fetched or already available
      const fetchedData1 = quoteData.enqquoteTerms; // Accessing the array inside quoteData

      console.log("fetchedData1 ===> ", fetchedData1);

      setDataToUpdate1(fetchedData1); 
      setShouldFetchData(false);
    }
  }, [shouldFetchData]);

  useEffect(() => {
    console.log("dataToUpdate1 updated ===> ", dataToUpdate1); // Log after the update
  }, [dataToUpdate1]);

  console.log('Quote sterm:', term1);
  const handleQuoteInputChange = (field, value) => {
    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      [field]: value,
    }));
  };

  const handleQdateChange = (name, value) => {
    setQuoteData({
      ...quoteData,
      [name]: value, // Update the qdate field
    });
  };



  // const handleTermChange = (e, index, type, id) => {
  //   const { checked, value } = e.target;

  //   setQuoteData((prevQuoteData) => {
  //     // Create a copy of the enqquoteTerms array
  //     const updatedTerms = [...prevQuoteData.enqquoteTerms];

  //     // Handle checkbox change
  //     if (type === 'checkbox') {
  //       updatedTerms[index] = {
  //         ...updatedTerms[index],
  //         checked: checked, // Update the checked property
  //         termId: checked ? id : 0, // If checked, set termId to the passed id; if unchecked, set it to 0
  //       };

  //       // Optionally filter terms based on the checked value (if needed)
  //       const filteredTerms = updatedTerms.filter(term => term.checked);
  //       console.log("filteredTerms", filteredTerms);



  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: [...updatedTerms], // Store the updated terms
  //       };
  //     }

  //     // Handle text field (name) change
  //     if (type === 'name') {
  //       if (updatedTerms[index]?.checked) { // Only update the name if the checkbox is checked
  //         updatedTerms[index].name = value; // Update the name property with the new value

  //       }
  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: [...updatedTerms], // Store the updated terms with the new name
  //       };
  //     }

  //     return prevQuoteData; // If no valid type, return the state as is
  //   });
  // };



  // const handleTermChange1 = (e, index, type, id, name) => {
  //   const { checked, value } = e.target;

  //   setQuoteData((prevQuoteData) => {

  //     const updatedTerms = [...prevQuoteData.enqquoteTerms];

  //     // Handle checkbox change
  //     if (type === 'checkbox') {
  //       updatedTerms[id] = {
  //         ...updatedTerms[id],
  //         checked: checked,
  //         termId: checked ? id : 0,
  //       };

  //       // Optionally filter terms based on the checked value (if needed)
  //       const filteredTerms = updatedTerms.filter(term => term.checked);
  //       console.log("filteredTerms", filteredTerms);

  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: updatedTerms, // Store the updated terms
  //       };
  //     }

  //     // Handle text field (name) change
  //     if (type === 'name') {
  //       if (updatedTerms[id]?.checked) {  // Only update name if checkbox is checked
  //         updatedTerms[id].name = value; // Update the name property with the new value
  //       }

  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: updatedTerms, // Store the updated terms with the new name
  //       };
  //     }

  //     return prevQuoteData; // If no valid type, return the state as is
  //   });
  // };
  const handleTermChange = (e, index, type, id) => {
    const { checked, value } = e.target;
  
    setQuoteData((prevQuoteData) => {
      // Create a copy of the enqquoteTerms array
      const updatedTerms = [...prevQuoteData.enqquoteTerms];
  
      // Handle checkbox change
      if (type === 'checkbox') {
        updatedTerms[index] = {
          ...updatedTerms[index],
          checked: checked, // Update the checked property
          termId: checked ? id : 0, // If checked, set termId to the passed id; if unchecked, set it to 0
        };
  
        // Optionally filter terms based on the checked value (for debugging/logging purposes)
        const filteredTerms = updatedTerms.filter(term => term.checked);
        console.log("filteredTerms", filteredTerms);
  
        return {
          ...prevQuoteData,
          enqquoteTerms: updatedTerms, // Store the updated terms array
        };
      }
  
      // Handle text field (name) change
      if (type === 'name') {
        if (updatedTerms[index]?.checked) { // Only update the name if the checkbox is checked
          updatedTerms[index] = {
            ...updatedTerms[index],
            name: value, // Update the name property with the new value
          };
        }
        return {
          ...prevQuoteData,
          enqquoteTerms: updatedTerms, // Store the updated terms array with the new name
        };
      }
  
      return prevQuoteData; // If no valid type, return the state as is
    });
  };
  
  // const handleTermChange1 = (e, index, type, id, name) => {
  //   const { checked, value } = e.target;
  
  //   setQuoteData((prevQuoteData) => {
  //     // Create a copy of the existing enqquoteTerms object
  //     const updatedTerms = { ...prevQuoteData.enqquoteTerms };
  
  //     // Handle checkbox change
  //     if (type === 'checkbox') {
  //       // Update the specific term's checked status
  //       updatedTerms[id] = {
  //         ...updatedTerms[id],
  //         checked: checked,
  //         termId: checked ? id : 0,
  //       };
  
  //       // Filter the terms based on the checked status
  //       const filteredTerms = Object.values(updatedTerms).filter(term => term.checked);
  //       console.log("filteredTerms", filteredTerms);
  
  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: [...updatedTerms], // Store the updated terms
  //       };
  //     }
  
  //     // Handle text field (name) change
  //     if (type === 'name') {
  //       if (updatedTerms[id]?.checked) {  // Only update name if checkbox is checked
  //         updatedTerms[id].name = value; // Update the name property with the new value
  //       }
  
  //       return {
  //         ...prevQuoteData,
  //         enqquoteTerms: [...updatedTerms], // Store the updated terms with the new name
  //       };
  //     }
  
  //     return prevQuoteData; // If no valid type, return the state as is
  //   });
  // };
  
  const handleTermChange1 = (e, index, type, id, name) => {
    const { checked, value } = e.target;
  
    setQuoteData((prevQuoteData) => {
      // Create a copy of the existing enqquoteTerms object
      const updatedTerms = { ...prevQuoteData.enqquoteTerms };
  
      // Handle checkbox change
      if (type === 'checkbox') {
        // Update the specific term's checked status
        updatedTerms[id] = {
          ...updatedTerms[id],
          checked: checked,
          termId: checked ? id : 0, // Set termId if checked, otherwise set to 0
        };
  
        // Log the filtered terms (for debugging purposes)
        const filteredTerms = Object.values(updatedTerms).filter(term => term.checked);
        console.log("filteredTerms", filteredTerms);
  
        return {
          ...prevQuoteData,
          enqquoteTerms: Object.values(updatedTerms), // Convert object to array for storage
        };
      }
  
      // Handle text field (name) change
      if (type === 'name') {
        if (updatedTerms[id]?.checked) { // Only update name if the checkbox is checked
          updatedTerms[id] = {
            ...updatedTerms[id],
            name: value, // Update the name property with the new value
          };
        }
  
        return {
          ...prevQuoteData,
          enqquoteTerms: Object.values(updatedTerms), // Convert object to array for storage
        };
      }
  
      return prevQuoteData; // If no valid type, return the state as is
    });
  };
  
  useEffect(() => {
    // Assuming you are fetching or setting quoteData at some point
    setQuoteData((prevData) => {
      const updatedEnqquoteTerms = prevData.enqquoteTerms.map(term => ({
        ...term,
        checked: true // Set checked to true by default
      }));

      // Filter to only include checked terms
      const filteredTerms = updatedEnqquoteTerms.filter(term => term.checked);

      return {
        ...prevData,
        enqquoteTerms: filteredTerms, // Store only the checked terms
      };


    });
  }, []);


  const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

  const contentToPrint = useRef(null);
  const handlePrint1 = useReactToPrint({
    onBeforePrint: () =>
      setIsPrintMode(true), onAfterPrint: () => setIsPrintMode(false),
    removeAfterPrint: true,


  });
  console.log('IsPrintMode', isPrintMode);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [masterChecked, setMasterChecked] = useState(false);
  const [masterChecked1, setMasterChecked1] = useState(false);

  // Function to handle the main checkbox click
  const handleMasterCheckboxChange = (e) => {
    const checked = e.target.checked;
    setMasterChecked(checked);

    // Update all checkboxes
    term.forEach((item, index) => {
      if (item.type === "0") {
        handleTermChange({ target: { checked } }, index, 'checkbox', item.id);
      }
    });
  };

  const handleMasterCheckboxChange1 = (e) => {
    const checked = e.target.checked;
    setMasterChecked1(checked);

    // Update all checkboxes
    term.forEach((item, index) => {
      if (item.type === "1") {
        handleTermChange1({ target: { checked } }, index, 'checkbox', item.id, item.name);
      }
    });
  };

  useEffect(() => {
    const allChecked = term.filter(item => item.type === "0").every((item, index) =>
      quoteData.enqquoteTerms[index]?.checked
    );
    setMasterChecked(allChecked);
  }, [quoteData.enqquoteTerms, term]);

  useEffect(() => {
    const allChecked1 = term.filter(item => item.type === "1").every((item, index) =>
      quoteData.enqquoteTerms[index]?.checked
    );
    setMasterChecked1(allChecked1);
  }, [quoteData.enqquoteTerms, term]);


  const handleInputChange = (index, field, value) => {
    const updatedData = [...quoteData.enqquotedet];
    updatedData[index][field] = value;
    setQuoteData((prevData) => ({
      ...prevData,
      enqquotedet: updatedData,
    }));
    console.log('quoteData', quoteData);
  };

  // Function to handle select changes (like tax id change)
  const handleSelectChange = (e, index) => {
    const updatedData = [...quoteData.enqquotedet];
    updatedData[index].taxid = e.target.value;
    setQuoteData((prevData) => ({
      ...prevData,
      enqquotedet: updatedData,
    }));
  };

  // Calculate the tax name by its id
  const getTaxNameById = (taxid) => {
    const selectedTax = gst.find((g) => g.id === taxid);
    return selectedTax ? selectedTax.name : '';
  };

  // Handle packing change
  const handlePackingChange = (value) => {
    const packpercentage = (parseFloat(value) / 100);
    const packingCharge = quoteData.qval * packpercentage;
    setPackPercentage(packpercentage)
    setQuoteData((prevData) => ({
      ...prevData,
      packchange: packingCharge,
    }));
  };

  const handleFreightChange = (value) => {
    const freightprecentage = (parseFloat(value) / 100);
    const freightCharge = quoteData.qval * freightprecentage;
    setFreightPercentage(freightprecentage);
    let fc = freightCharge;

    setQuoteData((prevData) => ({
      ...prevData,
      freight: Number(fc),
    }));
    console.log('quoteData', quoteData);
  };

  // Calculate totals
  useEffect(() => {
    let qval = 0;
    let taxamt = 0;

    quoteData.enqquotedet.forEach((item) => {
      const taxableamt = (item.qty || 0) * (item.unitprice || 0);
      const selectedTax = gst.find((g) => g.id === item.taxid);
      const taxRate = selectedTax ? selectedTax.per / 100 : 0;
      const taxAmount = taxableamt * taxRate;
      qval += taxableamt;
      taxamt += taxAmount;
    });

    setQuoteData((prevData) => ({
      ...prevData,
      qval,
      taxamt,
      total: qval + prevData.packchange + prevData.freight + taxamt,
    }));
  }, [quoteData.enqquotedet, quoteData.packchange, quoteData.freight, gst, setQuoteData]);


  // useEffect(() => {
  //   const filteredTerms = term1.filter(item => item.type === "0" ).map(item => ({
  //     id: 0,       // or generate an appropriate id if needed
  //     qid: 0,      // or assign the proper qid if available
  //     type: item.type,
  //     termId: item.id,
  //     name: item.name,
  //     checked: true
  //   }));

  //   const checkTerms = filteredTerms.filter(item => item.checked === true).map(item => ({
  //     id: 0,       // or generate an appropriate id if needed
  //     qid: 0,      // or assign the proper qid if available
  //     type: item.type,
  //     termId: item.id,
  //     name: item.name

  //   }));

  //   setQuoteData(prevQuoteData => ({
  //     ...prevQuoteData,
  //     enqquoteTerms: checkTerms,
  //   }));
  // }, [term]);

  const handleInputChange1 = (event) => {
    setQuoteData({
      ...quoteData,
      subject: event.target.value // Update subject value
    });
  };

  const [isSaved, setIsSaved] = useState(false);

  const [type0, setType0] = useState([]);
  const [type1, setType1] = useState([]);
  const [sname, setSname] = useState([]);

  useEffect(() => {

    const filteredData0 = dataToUpdate1.filter(item => item.type === '0' && item.checked === true);
    console.log("Filtered Data type 0: == -- >>", filteredData0);
    setType0(filteredData0)
    const filteredData1 = dataToUpdate1.filter(item => item.type === '1' && item.checked === true);
    console.log("Filtered Data: == -- >>", filteredData1);
    setType1(filteredData1)

    const filteredSnameData = filteredData0.map(filteredItem => {
      const matchedTerm = term.find(termItem => termItem.id === filteredItem.termId); // Match by termId
      return matchedTerm ? matchedTerm.sname : null; // Return sname if found, else null
    }).filter(Boolean); // Filter out null values

    console.log("Filtered sname Data: == -- >>", filteredSnameData);
    setSname(filteredSnameData); // Store the filtered sname values

  }, [dataToUpdate1, term]);


  return (
    <>   <Helmet>
      <title>Quotation</title>
    </Helmet>

      {!isSaved ? (
        <Box >
          <Box className="print-container">
            <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} mb={-2}>
                  <Grid container alignItems="center" spacing={1} direction="row">
                    <Grid item>
                      <img
                        src={require('src/assets/logosaarc.png')}
                        alt="Company Logo"
                        style={{ width: 40, height: 40 }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: 'red',
                          fontSize: 23,
                          fontFamily: 'Arial, sans-serif',
                          display: 'inline',
                        }}
                      >
                        {selectedCompany?.name?.split(' ')[0]}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: 22,
                          fontFamily: 'Arial, sans-serif',
                          display: 'inline',
                        }}
                      >
                        &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={7} sm={7}>

                  <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                    <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: 18 }}>
                      PE Molded Advanced CEMILAC certified Mil Std. Containers
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      {selectedCompany?.add1}, {selectedCompany?.add2}
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      {selectedCompany?.add3} - {selectedCompany?.add5}, {selectedCompany?.add4}, India
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      Phone: {selectedCompany?.phone} Fax: {selectedCompany?.mobile}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Arial, sans-serif', fontSize: 18 }}>
                      <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                    </Typography>
                    <Typography variant="body2" fontSize="18" fontFamily='Arial, sans-serif'>
                      <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                    </Typography>
                  </Box>
                  <Box>
                    {/* <br /> */}
                    <Grid item xs={12} sm={7} md={7} >
                      <Box sx={{ p: 1, borderRadius: '5px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 17, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                          To
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 14 }} variant="body2" paragraph>
                          {formData?.faddr1},{formData?.faddr2},<br />
                          {formData?.faddr3},{formData?.faddr5},<br />
                          {formData?.faddr4}, {formData?.faddr6} ,{formData?.faddr7}

                        </Typography>
                      </Box>
                    </Grid>
                  </Box>

                </Grid>

                <Grid item xs={5}>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                    BUDGETARY QUOTATION
                  </Typography>
                  <Grid container direction="row" spacing={1} mt={1}>

                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>SAARC Enquiry No:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                      <Typography variant="body2">{formData?.enqNo}</Typography>
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation No:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                      <Typography variant="body2">{quoteData.qno}</Typography>
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation Date:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography variant="body2">
                        {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                      </Typography>


                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer Ref:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >
                      {!isPrintMode ? (
                        <TextField
                          onChange={(e) => handleQuoteInputChange('cusref', e.target.value)}
                          variant="outlined"
                          value={formData?.enqquote?.[0]?.cusref}

                          // fullWidth
                          sx={{
                            width: '93%',
                            height: '30px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}
                        />
                      ) : (
                        <Typography variant="body2">{quoteData.cusref}</Typography>
                      )}
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC Name:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >
                      {!isPrintMode ? (
                        <TextField
                          onChange={(e) => handleQuoteInputChange('cuspocname', e.target.value)}
                          variant="outlined"
                          value={formData?.enqquote?.[0]?.cuspocname}
                          // fullWidth
                          sx={{
                            width: '93%',
                            height: '30px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}

                        />
                      ) : (
                        <Typography variant="body2">{quoteData.cuspocname}</Typography>
                      )}
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC No:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      {!isPrintMode ? (
                        <TextField
                          onChange={(e) => handleQuoteInputChange('cuspocno', e.target.value)}
                          variant="outlined"
                          value={formData?.enqquote?.[0]?.cuspocno}
                          // fullWidth
                          sx={{
                            width: '142%',
                            height: '30px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}

                        />
                      ) : (
                        <Typography variant="body2">{quoteData.cuspocno}</Typography>
                      )}
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Designation:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >
                      {!isPrintMode ? (
                        <TextField
                          onChange={(e) => handleQuoteInputChange('cusdesign', e.target.value)}
                          variant="outlined"
                          value={formData?.enqquote?.[0]?.cusdesign}
                          // fullWidth
                          sx={{
                            width: '93%',
                            height: '30px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}
                        />
                      ) : (
                        <Typography variant="body2">{quoteData.cusdesign}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}  >
                  <Box
                    sx={{
                      position: 'relative',
                      marginTop: '-5%',
                      width: '100%',
                      padding: isSmallScreen ? '0px' : '5px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Typography
                      variant="body2"
                      paragraph
                      fontFamily='Arial, sans-serif'
                      color='black'
                      sx={{
                        width: '100%',
                        padding: '10px',
                        boxSizing: 'border-box',
                      }}
                    >
                      Dear Sir/Madam,

                      {!isPrintMode ? (
                        <TextField
                          variant="outlined"
                          value={quoteData.subject} // Bind subject to TextField
                          onChange={handleInputChange1} // Handle input change
                          multiline
                          rows={isSmallScreen ? 4 : 2}
                          sx={{
                            width: '100%',
                            maxWidth: '1130px',
                            '& .MuiInputBase-root': {
                              height: 'auto',
                              padding: '10px',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: 'auto',
                            },
                          }}
                        />
                      ) : (
                        <div className="print-value" style={{
                          width: '100%',
                          maxWidth: '1130px',
                          padding: '10px',
                          boxSizing: 'border-box',
                        }}>
                          {quoteData.subject} {/* Display subject value in print mode */}
                        </div>
                      )}

                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <TableContainer
                // component={Paper}
                className="print-table"
                sx={{
                  width: '100%',
                  padding: isSmallScreen ? '0px' : '5px',
                  boxSizing: 'border-box',
                  overflowX: isPrintMode ? 'hidden' : 'auto',
                  overflowY: isPrintMode ? 'hidden' : 'auto',
                  marginTop: '-2%'
                }}
              >
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '10%' }}>Sl. No.</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '26%' }}>Description</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>SAARC MPN No</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '14%' }}>Quantity</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Unit Price</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sub-Total </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Tax</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Taxable Amount</TableCell>

                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Total Amount</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quoteData.enqquotedet.map((item, index) => {
                      const taxableamt = (item.qty || 0) * (item.unitprice || 0);
                      const selectedTax = gst.find((g) => g.id === item.taxid);
                      const taxRate = selectedTax ? selectedTax.per / 100 : 0;
                      const taxamt = taxableamt * taxRate;
                      const total = taxableamt + taxamt;

                      item.taxableamt = taxableamt;
                      item.taxamt = taxamt;
                      item.total = total;

                      return (
                        <TableRow key={index} sx={{ height: '30px' }}>
                          <TableCell align="center">{index + 1}</TableCell>

                          <TableCell align="left">
                            {isPrintMode ? (
                              <div className="print-desp">{item.desp}</div>
                            ) : (
                              <TextField
                                value={item.desp}
                                onChange={(e) => handleInputChange(index, 'desp', e.target.value)}
                                sx={{ width: '100%', height: '35px' }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {isPrintMode ? (
                              <div className="print-mpnno">{item.mpnno}</div>
                            ) : (
                              <TextField
                                value={item.mpnno}
                                onChange={(e) => handleInputChange(index, 'mpnno', e.target.value)}
                                sx={{ width: '100%', height: '35px' }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {isPrintMode ? (
                              <div className="print-qty">{item.qty}</div>
                            ) : (
                              <TextField
                                type="number"
                                value={item.qty || ''}
                                onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                sx={{ width: '100%', height: '35px' }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {isPrintMode ? (
                              <div className="print-unitprice">{item.unitprice}</div>
                            ) : (
                              <TextField
                                type="number"
                                value={item.unitprice || ''}
                                onChange={(e) => handleInputChange(index, 'unitprice', e.target.value)}
                                sx={{ width: '100%', height: '35px' }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">{taxableamt.toFixed(2)}</TableCell>

                          <TableCell align="center">
                            {!isPrintMode ? (
                              <TextField
                                select
                                value={item.taxid}
                                onChange={(e) => handleSelectChange(e, index)}
                                sx={{ width: '100%', height: '35px' }}
                              >
                                {gst.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <div className="print-tax">{getTaxNameById(item.taxid)}</div>
                            )}
                          </TableCell>

                          <TableCell align="center">{taxamt.toFixed(2)}</TableCell>

                          <TableCell align="center">{total.toFixed(2)}</TableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow>
                      <TableCell colSpan={8} align="right" sx={{ fontWeight: 'bold' }}>
                        SUB-TOTAL AMOUNT:
                      </TableCell>
                      <TableCell align="center">₹ {quoteData.qval.toFixed(2)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={7} align="right" sx={{ fontWeight: 'bold' }}>
                        PACKING CHARGE:
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          label="%"
                          onChange={(e) => handlePackingChange(e.target.value)}
                        />
                      </TableCell>
                      <TableCell align="center"> <TextField
                        value={quoteData.packchange} // Bind the current packchange value
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          setQuoteData((prevData) => ({
                            ...prevData,
                            packchange: isNaN(newValue) ? 0 : newValue, // Ensure the value is a number
                          }));
                        }}
                        label="Packing Charge" // Optionally label the field
                        type="number" // Use a number input to prevent non-numeric entries
                        InputProps={{
                          startAdornment: <span>₹</span>, // Add ₹ symbol before the input
                        }}
                      /></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={7} align="right" sx={{ fontWeight: 'bold' }}>
                        FREIGHT:
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          label="%"
                          onChange={(e) => handleFreightChange(e.target.value)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          value={quoteData.freight} // Bind the current freight value
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            setQuoteData((prevData) => ({
                              ...prevData,
                              freight: isNaN(newValue) ? 0 : newValue, // Ensure the value is a number
                            }));
                          }}
                          label="Freight" // Optionally label the field
                          type="number" // Use a number input to prevent non-numeric entries
                          InputProps={{
                            startAdornment: <span>₹</span>, // Add ₹ symbol before the input
                          }}
                        />
                      </TableCell>

                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={8} align="right" sx={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                        TOTAL TAX AMOUNT:
                      </TableCell>
                      <TableCell align="center">₹ {quoteData.taxamt.toFixed(2)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={8} align="right" sx={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                        TOTAL:
                      </TableCell>
                      <TableCell align="center" sx={{ borderTop: '1px solid #000' }}>₹ {quoteData.total.toFixed(2)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                  SPECIAL TERMS & CONDITIONS:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' no-print'>
                  <Typography>Select All</Typography>
                  <Checkbox
                    checked={masterChecked}
                    onChange={handleMasterCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Box>
              </Box>

              <br />

              <TableContainer className='no-print'>
                <Table aria-label="simple table">
                  <TableBody>
                    {term.filter(item => item.type === "0").map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          height: { xs: 'auto', sm: '60px', md: '80px' }, // Responsive row height
                        }}
                      >
                        {/* First Column: sname */}
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: 'GrayText',
                              fontSize: { xs: 12, sm: 14 }, // Responsive font size
                              fontFamily: 'Arial, sans-serif',
                            }}
                            variant="body2"
                          >
                            {console.log("SNAME ==> ", sname)
                            }
                            {item?.sname}:
                          </Typography>
                        </TableCell>

                        {/* Second Column: Editable TextField */}
                        <TableCell align="left">
                          <TextField
                            value={quoteData.enqquoteTerms[index]?.name || ''} // Ensure it's sourced from the state
                            onChange={(e) => handleTermChange(e, index, 'name', item.id)} // Use 'name' type for text field change
                            variant="outlined"
                            sx={{
                              width: "100%",
                              fontSize: { xs: 12, sm: 14 }, // Responsive font size
                              color: 'black',
                              fontFamily: 'Arial, sans-serif',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none', // Hides the border of the TextField
                                },
                              },
                            }}
                          />
                        </TableCell>

                        {/* Third Column: Checkbox */}
                        <TableCell align="center">
                          <Checkbox
                            checked={quoteData.enqquoteTerms[index]?.checked ?? false} // Ensure checkbox state is synced
                            onChange={(e) => handleTermChange(e, index, 'checkbox', item.id)} // Use 'checkbox' type for checkbox change
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


              <Box
                mt={2}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}
                  >
                    <strong>STANDARD TERMS & CONDITIONS:</strong>
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' no-print'>
                    <Typography>Select All</Typography>
                    <Checkbox
                      checked={masterChecked1}
                      onChange={handleMasterCheckboxChange1}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>
                </Box>

                <br />
                <TableContainer className=' no-print'>
                  <Table aria-label="simple table">
                    <TableBody>
                      {term
                        .filter(item => item.type === "1") // Filter terms with type === 1
                        .map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>

                              {/* First Column: name */}
                              <TableCell align="left">
                                <Typography
                                  sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }}
                                  variant="body2"
                                >
                                  {item.name}
                                </Typography>
                              </TableCell>

                              {/* Additional logic (Checkbox or other columns) */}
                              <TableCell align="center">
                                <Checkbox
                                  checked={quoteData.enqquoteTerms[item.id]?.checked ?? false} // Ensure checkbox state is synced
                                  onChange={(e) => handleTermChange1(e, index, 'checkbox', item.id, item.name)}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>

                  </Table>
                </TableContainer>
                <br />
                <Box
                  sx={{
                    width: '250px',
                    height: '45px',
                    bgcolor: '#e0e0e0',
                    p: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                    Queries to be Addressed to:
                  </Typography>
                </Box>
                <br />
                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                  Mr/s. V. Suganthy, Mob: 8807060127 | Email: marketing@saarctecs.com<br />
                  Mr/s. C. Srinivita, Mob: 8807060125 | Email: marketing@saarctecs.com<br />
                  Mr/M. Sridavi, Mob: 8807042319 | Email: applicationengg@saarctecs.com
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="45px"
                >
                  <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                    THANK YOU FOR YOUR BUSINESS SUPPORT
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="caption"><strong>Prepared By</strong></Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Checked By</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box><Box>
            <Button sx={{ position: 'absolute', left: 10, bottom: 25, margin: 1 }} onClick={handleBack} variant="contained" color="primary" className="no-print" >
              Back
            </Button>

            <Box display="flex" justifyContent="flex-end" p={2}>

              <Button
                variant="contained"
                color="primary"

                onClick={async () => {
                  await handleSubmit(); // Save the quotation first

                
                }}
                sx={{ position: 'absolute', right: 40, bottom: 25, margin: 1 }}

                className="no-print"
              >
                Save
              </Button>
            </Box>
          </Box>

        </Box>
      ) : (
        <Box ref={contentToPrint}>
          <Box className="print-container">
            <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} mb={-2}>
                  <Grid container alignItems="center" spacing={1} direction="row">
                    <Grid item>
                      <img
                        src={require('src/assets/logosaarc.png')}
                        alt="Company Logo"
                        style={{ width: 40, height: 40 }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: 'red',
                          fontSize: 23,
                          fontFamily: 'Arial, sans-serif',
                          display: 'inline',
                        }}
                      >
                        {selectedCompany?.name?.split(' ')[0]}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: 22,
                          fontFamily: 'Arial, sans-serif',
                          display: 'inline',
                        }}
                      >
                        &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={7} sm={7}>

                  <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                    <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: 18 }}>
                      PE Molded Advanced CEMILAC certified Mil Std. Containers
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      {selectedCompany?.add1}, {selectedCompany?.add2}
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      {selectedCompany?.add3} - {selectedCompany?.add5}, {selectedCompany?.add4}, India
                    </Typography>
                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                      Phone: {selectedCompany?.phone} Fax: {selectedCompany?.mobile}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Arial, sans-serif', fontSize: 18 }}>
                      <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                    </Typography>
                    <Typography variant="body2" fontSize="18" fontFamily='Arial, sans-serif'>
                      <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                    </Typography>
                  </Box>
                  <Box>
                    {/* <br /> */}
                    <Grid item xs={12} sm={7} md={7} >
                      <Box sx={{ p: 1, borderRadius: '5px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 17, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                          To
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 14 }} variant="body2" paragraph>
                          {formData?.faddr1},{formData?.faddr2},<br />
                          {formData?.faddr3},{formData?.faddr5},<br />
                          {formData?.faddr4}, {formData?.faddr6} ,{formData?.faddr7}

                        </Typography>
                      </Box>
                    </Grid>
                  </Box>

                </Grid>

                <Grid item xs={5}>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                    BUDGETARY QUOTATION
                  </Typography>
                  <Grid container direction="row" spacing={1} mt={1}>

                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>SAARC Enquiry No:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                      <Typography variant="body2">{formData?.enqNo}</Typography>
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation No:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                      <Typography variant="body2">{quoteData.qno}</Typography>
                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation Date:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography variant="body2">
                        {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                      </Typography>


                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer Ref:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Typography variant="body2">{quoteData.cusref}</Typography>

                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC Name:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Typography variant="body2">{quoteData.cuspocname}</Typography>

                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC No:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={4}>

                      <Typography variant="body2">{quoteData.cuspocno}</Typography>

                    </Grid><br />
                    <Grid item xs={12} sm={6} md={6} >
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Designation:</Typography></Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Typography variant="body2">{quoteData.cusdesign}</Typography>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}  >
                  <Box
                    sx={{
                      position: 'relative',
                      marginTop: '-5%',
                      width: '100%',
                      padding: isSmallScreen ? '0px' : '5px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Typography
                      variant="body2"
                      paragraph
                      fontFamily='Arial, sans-serif'
                      color='black'
                      sx={{
                        width: '100%',
                        padding: '10px',
                        boxSizing: 'border-box',
                      }}
                    >
                      Dear Sir/Madam,
                      <br />

                      <div className="print-value" style={{
                        width: '100%',
                        maxWidth: '1130px',
                        padding: '10px',
                        boxSizing: 'border-box',
                      }}>
                        {quoteData.subject} {/* Display subject value in print mode */}
                      </div>


                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <div
                className="print-table"
                style={{
                  width: '100%',
                  padding: isSmallScreen ? '0px' : '5px',
                  boxSizing: 'border-box',
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  marginTop: '-2%'
                }}
              >
                <table style={{ tableLayout: 'fixed', width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sl. No.</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '30%' }}>Description</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>SAARC MPN No</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '24%' }}>Quantity</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Unit Price</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Sub-Total</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Tax</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Taxable Amount</th>
                      <th style={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteData.enqquotedet.map((item, index) => (
                      <tr key={index} style={{ height: '30px' }}>
                        <td align="center">{index + 1}</td>
                        <td align="left">
                          <div className="print-desp">{item.desp}</div>
                        </td>
                        <td align="center">
                          <div className="print-mpnno">{item.mpnno}</div>
                        </td>
                        <td align="center">
                          <div className="print-qty">{item.qty}</div>
                        </td>
                        <td align="center">
                          <div className="print-unitprice">{item.unitprice}</div>
                        </td>
                        <td align="center">{item.taxableamt.toFixed(2)}</td>
                        <td align="center">
                          <div className="print-tax">{getTaxNameById(item.taxid)}</div>
                        </td>
                        <td align="center">{item.taxamt.toFixed(2)}</td>
                        <td align="center">{item.total.toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={8} align="right" style={{ fontWeight: 'bold' }}>SUB-TOTAL AMOUNT:</td>
                      <td align="center">₹ {quoteData.qval.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan={7} align="right" style={{ fontWeight: 'bold' }}>PACKING CHARGE:</td>
                      <td align="center">
                        {packPercentage}{"%"}
                      </td>
                      <td align="center">
                        {quoteData.packchange}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} align="right" style={{ fontWeight: 'bold' }}>FREIGHT:</td>
                      <td align="center">
                        {freightPercentage}{"%"}
                      </td>
                      <td align="center">

                        {quoteData.freight}

                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8} align="right" style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>TOTAL TAX AMOUNT:</td>
                      <td align="center">₹ {quoteData.taxamt.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan={8} align="right" style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>TOTAL:</td>
                      <td align="center" style={{ borderTop: '1px solid #000' }}>₹ {quoteData.total.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                  SPECIAL TERMS & CONDITIONS:
                </Typography>

              </Box>

              <div
                className='print-terms'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'initial',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  position: 'relative',
                  left: '52px'
                }}
              >
                {/* First Box: Displaying sname values */}
                <div>
                  {sname.length > 0 ? (
                    sname.map((snameItem, index) => (
                      <div key={index} style={{ marginBottom: '1rem' }}>
                        <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                          {snameItem}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No terms found</p>
                  )}
                </div>

                {/* Second Box: Displaying name values where termId > 0 is changed to id */}
                <div style={{ position: 'relative', left: '40px' }}>
                  {type0// Ensures id > 0 and type '0'
                    .map((item, index) => (
                      <div key={index} style={{ marginBottom: '1rem' }}>
                        <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                          : {item?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </div>

              <Box
                mt={2}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}
                  >
                    <strong>STANDARD TERMS & CONDITIONS:</strong>
                  </Typography>


                </Box>
                <div>
                  {type1.map((item, index) => (
                    <p key={index}>
                      {index + 1}. {item.name}
                    </p>
                  ))}
                </div>

                <br />
                <Box
                  sx={{
                    width: '250px',
                    height: '45px',
                    bgcolor: '#e0e0e0',
                    p: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                    Queries to be Addressed to:
                  </Typography>
                </Box>
                <br />
                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                  Mr/s. V. Suganthy, Mob: 8807060127 | Email: marketing@saarctecs.com<br />
                  Mr/s. C. Srinivita, Mob: 8807060125 | Email: marketing@saarctecs.com<br />
                  Mr/M. Sridavi, Mob: 8807042319 | Email: applicationengg@saarctecs.com
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="45px"
                >
                  <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                    THANK YOU FOR YOUR BUSINESS SUPPORT
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="caption"><strong>Prepared By</strong></Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Checked By</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box><Box>
            <Button sx={{ position: 'absolute', left: 10, bottom: 25, margin: 1 }} onClick={handleBack} variant="contained" color="primary" className="no-print" >
              Back
            </Button>

            <Box display="flex" justifyContent="flex-end" p={2}>

              <Button
                variant="contained"
                color="primary"
                sx={{ position: 'absolute', right: 40, bottom: 25, margin: 1 }}
                className="no-print"
                onClick={async () => {
                  // await handleSubmit(); // Save the quotation first

                  handlePrint1(null, () => contentToPrint.current); // Trigger the print after successful save
                }}
              >
                Print
              </Button>
            </Box>
          </Box>

        </Box>
      )}
      <style>
        {`
        @media print {
                      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                          text-align: left;
                          
                          bottom: 0;
                          right: 0;
                          top: -5px;
                          left: 0;
                          margin: 0;
                          padding: 0 8px;
                          pointer-events: none;
                          border-radius: inherit;
                          border-style: solid;
                          border-width: 0px !important;
                          overflow: hidden;
                          min-width: 0%;
                          border-color: rgba(0, 0, 0, 0.23);
                           .print-table {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 9pt;
  }

  .no-print {
    display: none;
  }

  .print-desp,
  .print-mpnno,
  .print-qty,
  .print-unitPrice,
  .print-tax,
  .print-terms,
  .print-igst {
    font-size: 9pt;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

                      }
                            
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                        @media print {
  .no-print {
    display: none;
  }
   
}
    @media screen {
      .print-terms {
        display: none; /* Hides the Typography on screen */
      }

      .table-container {
        display: block; /* Shows the TableContainer on screen */
      }
    }
                `}
      </style>

    </>
  );
};


export default Quotation;
