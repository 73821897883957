import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Data {
  id: number;
  name: string;
  fdate: Date;
  todate: Date;
  isactive: string;
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

function FinancialYear() {

  // For Grid Table
  const [data, setData] = useState<Data[]>([]);

  // For Grid Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedDate, setSelectedDate] = useState(null);

  //Form data 
  const [formData, setFormData] = useState<any>({
    id: '',
    name: '',
    fdate: '',
    todate: '',
    isactive: ''
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {

    console.log(rowsPerPage.toString(), "size");
    console.log((page + 1).toString(), "page");

    apiService.getFinancialYear(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    })
  }, [rowsPerPage, page, formData]);

  //For Pagination 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Textbox and Select box onchage
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      name: '',
      fdate: '',
      todate: '',
      isactive: ''
    });
    setSelectedItem(null);
  };


  // Insert and Update 
  let submit = (event: any) => {
    event.preventDefault(); // Prevents default form submission behavior
    // Insert
    if (selectedItem == null) {

      let data = {

        "name": formData?.name,
        "fdate": formData?.fdate,
        "todate": formData?.todate,
        "isactive": formData?.isactive,
        "cby": "1"
      };
      apiService.insertFinancialYear(data
      ).then(response => {
        console.log("response", response.data);
        if (response?.data.key == "400") {
          errorToast(response?.data?.message)
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            name: '',
            fdate: '',
            todate: '',
            isactive: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }

    // Update
    else {
      let data = {
        "name": formData?.name,
        "fdate": formData?.fdate,
        "todate": formData?.todate,
        "isactive": formData?.isactive,
        "cby": "1"
      };
      apiService.updateFinancialYear(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            name: '',
            fdate: '',
            todate: '',
            isactive: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }
  };

  const handleEditClick = (item: Data) => {

    setFormData({
      id: item?.id.toString(),
      name: item?.name,
      fdate: item?.fdate,
      todate: item?.todate,
      isactive: item?.isactive,
    });
    setSelectedItem(item);
    setOpen(true);
  };


  return (
    <>
      <Helmet>
        <title>Financial Year - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="Financial Year"
          subHeading=""
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // alignItems: 'flex-end',
            mt: -3.5, // Add some margin-bottom for spacing
          }}
        >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New
          </Button></Box>
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>From Date</TableCell>
                      <TableCell>To Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row: any, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.fdate}</TableCell>
                        <TableCell>{row.todate}</TableCell>
                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data.length == 0 ? 0 : data[0]["count"]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="dense"
                id="name"
                label="Name"
                name='name'
                fullWidth
                value={formData?.name}
                onChange={handleChange}
                style={{ marginBottom: '18px' }}
              />
              <TextField
                id="fdate"
                label="From Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="fdate"
                value={formData?.fdate}
                onChange={handleChange}
                fullWidth
                style={{ marginBottom: '18px' }}
              />
              <TextField
                id="todate"
                label="To Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="todate"
                value={formData?.todate}
                onChange={handleChange}
                fullWidth

              />


              <TextField
                select
                margin="dense"
                label="Status"
                name='isactive'
                fullWidth
                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                onChange={handleChange}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary">
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />

    </>
  );
}

export default FinancialYear;