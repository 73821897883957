import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Button,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    MenuItem,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination
} from '@mui/material';
import { useState, SyntheticEvent, useEffect, ChangeEvent, FormEvent, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { number, string } from 'prop-types';
import apiService from 'src/services/api-service';
import axios from 'axios';
import { Attachment, Login } from '@mui/icons-material';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { NumberFormat } from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Data {
    tableRows: [
        {
            desp: string,
            qty: number,
            idenqdet: number,
            essentialTableRows: [
                {
                    essid: number,
                    requirements: string,
                    essdatid: number,
                    compliance: string,
                    attach: File
                }
            ],
        }
    ],
    updcheck: boolean;
    finYearId: number;
    lenessrow: number;
    empId: number;
    enqID: number;
    typeID: number;
    newEx: string;
    modeId: number;
    divId: number;
    rfxEnqNo: string;
    dueOn: Date;
    statusId: string;
    desp: string;
    basicValue: number;
    ccperson: string;
    cdesign: string;
    cmobile: string;
    cmail: string;
    tcperson: string;
    tdesign: string;
    tmobile: string;
    tmail: string;
    bankName: string;
    branchname: string;
    accNo: string;
    ifsc: string;
    isExcus: boolean;
    excusid: number;
    sname: string;
    code: string;
    name: string;
    city: string;
    ceo: string;
    md: string;
    mp: string;
    caddr1: string;
    caddr2: string;
    caddr3: string;
    caddr4: string;
    caddr5: string;
    caddr6: string;
    caddr7: string;
    faddr1: string;
    faddr2: string;
    faddr3: string;
    faddr4: string;
    faddr5: string;
    faddr6: string;
    faddr7: string;
    panno: string;
    tinno: number;
    email: string;
    // omobile: string;
    pmobile: string;
    gstno: string;
    // nbus: string;
    // ncomp: string;
    // datestart: Date;
    // cpname: string;
    // cpdesign: string;
    // cpmobile: string;
    // cpmail: string;
    // annualturnover: string;
    // buildOR: string;
    followtype: number;
    followdesc: string;
    isqul: boolean;
    nextfollow: Date;
    status: string;
    priority: string;
    // nfteamid: string;
    // nfempid: string;
    attach: string;
    divname: string;

}

interface Type {
    id: number;
    name: string;
}
interface Mode {
    id: number;
    name: string;
}
interface Division {
    id: number;
    name: string;
}
interface City {
    id: number;
    sname: string;
}
interface Status {
    id: number;
    name: string;
}
interface Followtype {
    id: number;
    name: string;
}
interface EssDta {
    id: number;
    name: string;
    compliance: String,
    isattach: 1;
}

interface RowData {
    id: number;
    code: string;
    name: string;
    place: string;
    mobile: string;
    mail: string;
    contactPerson: string;
    isMajor: boolean;
}
interface FollowDataItem {

    id: number;
    fdate: Date; // Change from string to Date
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: Date; // Change from string to Date
    statusid: number;
    ftypename: string;
    fbyname: string;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;


}
type FollowData = FollowDataItem[];

// Interface for essentialTableRows
interface EssentialTableRow {
    essid: number;
    enqdetid: number;
    essdatid: number;
    compliance: string;
    attach: string;
}

// Interface for tableRows
interface TableRows {
    id: number;
    idenqdet: number;
    desp: string;
    mpnno: string | null;
    qty: number;
    changedet: string | null;
    reasonchange: string | null;
    spltestcon: string | null;
    casemodel: string | null;
    essentialTableRows: EssentialTableRow[];
    enqecr: any[]; // Add specific type if needed
}

// Interface for enqfollow
interface EnqFollow {
    id: number;
    fdate: string;
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: string;
    statusid: number;
    ftypename: string;
    fbyname: string | null;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;
}

// Main interface for formData
// Interface for the enquiry data
interface Enquiry {
    enqNo: string;
    enqdate: string;
    tableRows: TableRows[];
    enqfollow: EnqFollow[];
    enqquote: any[] | null; // Add specific structure if needed
    updcheck: boolean;
    finYearId: number;
    lenessrow: any | null;
    empId: number;
    enqID: number | null;
    typeID: number;
    modeId: number;
    divId: number;
    rfxEnqNo: string | null;
    dueOn: string;
    statusId: number;
    desp: string | null;
    basicValue: number;
    ccperson: string;
    cdesign: string;
    cmobile: string;
    cmail: string;
    tcperson: string;
    tdesign: string;
    tmobile: string;
    tmail: string;
    bankName: string;
    branchname: string;
    accNo: string;
    ifsc: string;
    isExcus: boolean;
    excusid: number | null;
    sname: string;
    code: string;
    name: string;
    city: number;
    ceo: string | null;
    caddr1: string;
    caddr2: string;
    caddr3: string;
    caddr4: string;
    caddr5: string;
    caddr6: string;
    caddr7: string;
    faddr1: string;
    faddr2: string;
    faddr3: string;
    faddr4: string;
    faddr5: string;
    faddr6: string;
    faddr7: string;
    panno: string;
    tinno: string;
    email: string;
    omobile: string | null;
    pmobile: string;
    gstno: string;
    nbus: string | null;
    ncomp: string | null;
    datestart: string | null;
    cpname: string | null;
    cpdesign: string | null;
    cpmobile: string | null;
    cpmail: string | null;
    annualturnover: number | null;
    buildOR: string | null;
    followtype: string | null;
    followdesc: string | null;
    isqul: boolean | null;
    nextfollow: string | null;
    priority: string;
    divname: string;
}

// Main formData interface
interface FormData {
    enquiry: Enquiry[];
}



interface DropdownData {
    name: string;
    id: number;
}

interface LocationState {
    message?: string;
}

interface Priority {
    id: number;
    name: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

interface LocationState1 {
    id: string;
}


const UpdateLead: React.FC = () => {

    const location = useLocation();
    const state = location.state as LocationState1;


    // const id = state?.id ? Number(state.id) : undefined;
    const [value, setValue] = useState(3);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [customerType, setCustomerType] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [data, setData] = useState<Data[]>([]);
    const [type, setType] = useState<Type[]>([]);
    const [mode, setMode] = useState<Mode[]>([]);
    const [city, setCity] = useState<City[]>([]);
    const [status, setStatus] = useState<Status[]>([]);
    const [follow, setFollow] = useState<Followtype[]>([]);
    const [division, setDivision] = useState<Division[]>([]);
    const [rowdata, setRowdata] = useState<RowData[]>([]);
    const [essdata, setEssdata] = useState<EssDta[]>([]);
    // const [essDataValue, setEssDataValue] = useState<EssDta[]>([]);
    const [followData, setFollowData] = useState<FollowData>([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowdet, setSelectedRowdet] = useState(null);
    // const [data, setData] = useState<RowData[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [essDataValue, setEssDataValue] = useState<DropdownData[]>([]);
    const [isPMobileValid, setIsPMobileValid] = useState(true);
    // const [isCMobileValid, setIsCMobileValid] = useState(true);
    const [isTMobileValid, setIsTMobileValid] = useState(true);
    const [isCAMobileValid, setIsCAMobileValid] = useState(true);
    const [isFAMobileValid, setIsFAMobileValid] = useState(true);

    // const [isEmailValid, setIsEmailValid] = useState(true);
    const [isCEmailValid, setIsCEmailValid] = useState(true);
    const [isTEmailValid, setIsTEmailValid] = useState(true);
    const [isCAEmailValid, setIsCAEmailValid] = useState(true);
    const [isFAEmailValid, setIsFAEmailValid] = useState(true);
    const [quote, setQuote] = useState([]);
    const [priority, setPriority] = useState<Priority[]>([]);
    const [formData, setFormData] = useState<any>({
        enquiry: [{
            enqNo: "",
            enqdate: "",
            tableRows: [{
                id: 0,
                idenqdet: 0,
                desp: "",
                mpnno: "",
                qty: 0,
                changedet: "",
                reasonchange: "",
                spltestcon: "",
                casemodel: "",
                essentialTableRows: [{
                    essid: 0,
                    enqdetid: 0,
                    essdatid: 0,
                    compliance: "",
                    attach: ""
                }],
                enqecr: []
            }],
            enqfollow: [{
                id: 0,
                fdate: new Date().toISOString(),
                ftypeid: 0,
                priority: "",
                fby: 0,
                desp: "",
                nfdate: new Date().toISOString(),
                statusid: 0,
                ftypename: "",
                fbyname: '',
                statusname: "",
                fdate_s: "",
                nfdate_s: ""
            }],
            enqquote: "",
            updcheck: false,
            finYearId: 0,
            lenessrow: "",
            empId: 0,
            enqID: "",
            typeID: 0,
            modeId: 0,
            divId: 0,
            rfxEnqNo:'',
            dueOn: "",
            statusId: 0,
            desp: "",
            basicValue: 0,
            ccperson: "",
            cdesign: "",
            cmobile: "",
            cmail: "",
            tcperson: "",
            tdesign: "",
            tmobile: "",
            tmail: "",
            bankName: "",
            branchname: "",
            accNo: "",
            ifsc: "",
            isExcus: false,
            excusid: "",
            sname: "",
            code: "",
            name: "",
            city: 0,
            ceo: "",
            caddr1: "",
            caddr2: "",
            caddr3: "",
            caddr4: "",
            caddr5: "",
            caddr6: "",
            caddr7: "",
            faddr1: "",
            faddr2: "",
            faddr3: "",
            faddr4: "",
            faddr5: "",
            faddr6: "",
            faddr7: "",
            panno: "",
            tinno: "",
            email: "",
            omobile: "",
            pmobile: "",
            gstno: "",
            nbus: "",
            ncomp: "",
            datestart: "",
            cpname: "",
            cpdesign: "",
            cpmobile: "",
            cpmail: "",
            annualturnover: "",
            buildOR: "",
            followtype: "",
            followdesc: "",
            isqul: "",
            nextfollow: "",
            priority: "",
            divname: ""
        }]
    });


  const [leadData, setLeadData] = useState({
    tableRows: [
      {
        id: 0,
        enqdetid: 0,
        desp: '',
        mpnno: '',
        qty: 0,
        changedet: '',
        reasonchange: '',
        spltestcon: '',
        casemodel: '',
        essentialTableRows: [
          {
            essid: 0,
            enqdetid: 0,
            essdatid: 0,
            compliance: '',
            attach: '',
          },
        ],
      },
    ],
    enqNo: '',
    enqdate: '',
    updcheck: true,
    finYearId: 0,
    lennessrow: 0,
    empId: 0,
    enqID: 0,
    typeID: 0,
    newEx: '',
    modeId: 0,
    divId: 0,
    rfxEnqNo: '',
    dueOn: '',
    statusId: 0,
    desp: '',
    basicValue: 0,
    ccperson: '',
    cdesign: '',
    cmobile: '',
    cmail: '',
    tcperson: '',
    tdesign: '',
    tmobile: '',
    tmail: '',
    bankName: '',
    branchname: '',
    accNo: '',
    ifsc: '',
    isExcus: true,
    excusid: 0,
    sname: '',
    code: '',
    name: '',
    city: 0,
    ceo: '',
    md: '',
    mp: '',
    caddr1: '',
    caddr2: '',
    caddr3: '',
    caddr4: '',
    caddr5: '',
    caddr6: '',
    caddr7: '',
    faddr1: '',
    faddr2: '',
    faddr3: '',
    faddr4: '',
    faddr5: '',
    faddr6: '',
    faddr7: '',
    panno: '',
    tinno: '',
    email: '',
    omobile: '',
    pmobile: '',
    gstno: '',
    nbus: '',
    ncomp: '',
    datestart: '',
    cpname: '',
    cpdesign: '',
    cpmobile: '',
    cpmail: '',
    annualturnover: '',
    buildOR: '',
    followtype: 0,
    followdesc: '',
    isqul: true,
    nextfollow: '',
    priority: '',
    nfteamid: '',
    nfempid: '',
   
    divname: ''
  });

  // Example function to update the data
  const handleUpdateLeadData = (newData: any) => {
    setLeadData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };



    // const [formData, setFormData] = useState<any>(null);
    // const [followData, setFollowData] = useState<any>(null);
    // const [enqQuote, setEnqQuote] = useState<EnqQuote[]>([]);

    // const [enquiryDetails, setEnquiryDetails] = useState<EnquiryDetail[]>([]);

    const [currentIndex, setCurrentIndex] = useState<number>(0);


    useEffect(() => {
        console.log(state.id, "lead");
        if (state.id) {
            console.log(state.id, "lead in get data");
            apiService.leadgetId(Number(state.id))
                .then(response => {
                    const data = response.data;
                    console.log("response.data", response.data);
                    setFormData(data as FormData);
                    console.log("formData", formData);
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [state.id]);



    useEffect(() => {
        apiService.getDropdown("crm_mas_essdata").then(response => {
            setEssdata(response.data);

            setEssDataValue(response.data?.map((data) => {
                return {
                    requirements: data?.name,
                    essdatid: data?.id,
                    compliance: ''
                }
            }))
        }).catch((err) => {
            console.log("err", err)
        });
    }, []);

    useEffect(() => {


        apiService.getDropdown("crm_mas_type").then(response => {
            setType(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_mode").then(response => {
            setMode(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_division").then(response => {
            setDivision(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_status").then(response => {
            setStatus(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_followtype").then(response => {
            setFollow(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_priority").then(response => {
            setPriority(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_essdata").then(response => {
            setEssdata(response.data);

            setEssDataValue(response.data?.map((data) => {
                return {
                    requirements: data?.name,
                    essdatid: data?.id,
                    compliance: ''
                }
            }))
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_city").then(response => {
            setCity(response.data);
        }).catch((err) => {
            console.log("err", err)
        });


    }, [formData, page, rowsPerPage]);

    const handleQuotation = (id: number) => {
        console.log("id", id); 

        navigate('/crm/quotation', { state: { id } });

    }



    const handleEssential = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }



    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validateForm = () => {
        const requiredFields = [
            'typeID', 'name', 'modeId', 'divname', 'gstno', 'panno', 'email',
            'ccperson', 'cdesign', 'cmobile', 'cmail', 'tcperson', 'tdesign', 'tmobile', 'tmail', 'followtype',
            'statusId', 'nextfollow', 'priority', 'followdesc'
        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};

        requiredFields.forEach((field) => {
            if (!formData.enquiry[0][field]) {
                newErrors[field] = `${field} is required`;
                valid = false;
            }
        });

        // Email specific validation
        if (formData.enquiry[0]?.email && !isEmailValid(formData.enquiry[0]?.email)) {
            newErrors.email = 'Invalid email id';
            valid = false;
        }
        if (formData.enquiry[0]?.cmobile && !isCMobileValid(formData.enquiry[0]?.cmobile)) {
            newErrors.cmobile = 'Invalid mobile number';
            valid = false;
        }
        if (formData.enquiry[0]?.cmail && !isEmailValid(formData.enquiry[0]?.cmail)) {
            newErrors.cmail = 'Invalid email id';
            valid = false;
        }
        if (formData.enquiry[0]?.tmobile && !isCMobileValid(formData.enquiry[0]?.cmobile)) {
            newErrors.cmobile = 'Invalid mobile number';
            valid = false;
        }
        if (formData.enquiry[0]?.tmail && !isEmailValid(formData.enquiry[0]?.cmail)) {
            newErrors.cmail = 'Invalid email id';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };
    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isCMobileValid = (mobile: string) => {
        const mobileRegex = /^[0-9]{10}$/; // Example regex for 10-digit mobile numbers
        return mobileRegex.test(mobile);
    };



    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            console.log('Form is invalid. Please fill all required fields.');
            return;
        }

        try {
            const updatedLeadData = {
                ...leadData, // Spread existing leadData
            
                tableRows: formData.enquiry[0].tableRows, 
            
                enqNo: formData.enquiry[0].enqNo,                    
                enqdate: formData.enquiry[0].enqdate,
                updcheck: formData.enquiry[0].updcheck,
                finYearId: formData.enquiry[0].finYearId,
                lenessrow: formData.enquiry[0].lenessrow,
                empId: formData.enquiry[0].empId,
                enqID: formData.enquiry[0].enqID,
                typeID: formData.enquiry[0].typeID,
                modeId: formData.enquiry[0].modeId,
                divId: formData.enquiry[0].divId,
                rfxEnqNo: formData.enquiry[0].rfxEnqNo,
                dueOn: formData.enquiry[0].dueOn,
                statusId: formData.enquiry[0].statusId,
                desp: formData.enquiry[0].desp,
                basicValue: formData.enquiry[0].basicValue,
                ccperson: formData.enquiry[0].ccperson,
                cdesign: formData.enquiry[0].cdesign,
                cmobile: formData.enquiry[0].cmobile,
                cmail: formData.enquiry[0].cmail,
                tcperson: formData.enquiry[0].tcperson,
                tdesign: formData.enquiry[0].tdesign,
                tmobile: formData.enquiry[0].tmobile,
                tmail: formData.enquiry[0].tmail,
                bankName: formData.enquiry[0].bankName,
                branchname: formData.enquiry[0].branchname,
                accNo: formData.enquiry[0].accNo,
                ifsc: formData.enquiry[0].ifsc,
                isExcus: formData.enquiry[0].isExcus,
                excusid: formData.enquiry[0].excusid,
                sname: formData.enquiry[0].sname,
                code: formData.enquiry[0].code,
                name: formData.enquiry[0].name,
                city: formData.enquiry[0].city,
                ceo: formData.enquiry[0].ceo,
                caddr1: formData.enquiry[0].caddr1,
                caddr2: formData.enquiry[0].caddr2,
                caddr3: formData.enquiry[0].caddr3,
                caddr4: formData.enquiry[0].caddr4,
                caddr5: formData.enquiry[0].caddr5,
                caddr6: formData.enquiry[0].caddr6,
                caddr7: formData.enquiry[0].caddr7,
                faddr1: formData.enquiry[0].faddr1,
                faddr2: formData.enquiry[0].faddr2,
                faddr3: formData.enquiry[0].faddr3,
                faddr4: formData.enquiry[0].faddr4,
                faddr5: formData.enquiry[0].faddr5,
                faddr6: formData.enquiry[0].faddr6,
                faddr7: formData.enquiry[0].faddr7,
                panno: formData.enquiry[0].panno,
                tinno: formData.enquiry[0].tinno,
                email: formData.enquiry[0].email,
                omobile: formData.enquiry[0].omobile,
                pmobile: formData.enquiry[0].pmobile,
                gstno: formData.enquiry[0].gstno,
                nbus: formData.enquiry[0].nbus,
                ncomp: formData.enquiry[0].ncomp,
                datestart: formData.enquiry[0].datestart,
                cpname: formData.enquiry[0].cpname,
                cpdesign: formData.enquiry[0].cpdesign,
                cpmobile: formData.enquiry[0].cpmobile,
                cpmail: formData.enquiry[0].cpmail,
                annualturnover: formData.enquiry[0].annualturnover,
                buildOR: formData.enquiry[0].buildOR,
                followtype: formData.enquiry[0].followtype,
                followdesc: formData.enquiry[0].followdesc,
                isqul: formData.enquiry[0].isqul,
                nextfollow: formData.enquiry[0].nextfollow,
                priority: formData.enquiry[0].priority,
                divname: formData.enquiry[0].divname,
            
            };
            
    
            console.log("Updated Lead Data:", updatedLeadData);
            const dataToUpdate = updatedLeadData;
            console.log("dataToUpdate", dataToUpdate);

            const response = await apiService.leadupdate(dataToUpdate);
            // navigate('/crm/enquiry?status=success');
            navigate('/MarketingCRM/LeadEnquiry', { state: { message: 'Lead updated successfully!' } });
            toast.success(response.data);
            console.log('Form updated successfully:', response.data);




        } catch (error: any) {
            if (error.response) {
                console.error('Error response:', error.response);
                errorToast(`Error: ${error.response.data.message || error.response.status}`);
            } else if (error.request) {
                console.error('Error request:', error.request);
                errorToast('Error: No response received from server');
            } else {
                console.error('Error message:', error.message);
                errorToast(`Error: ${error.message}`);
            }
            console.error('Error config:', error.config);
        }
    };
    const validateMobile = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only) 
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);

    };
    const validateMobile1 = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only) 
        const mobileRegex = /^[0-9]{9}$/;
        return mobileRegex.test(mobile);

    };
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[a-zA-Z]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        return emailRegex.test(email);
    };

    const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === 'pmobile') {
            setIsPMobileValid(validateMobile(value));
        }
        if (name === 'caddr6') {
            setIsCAMobileValid(validateMobile(value));
        }
        if (name === 'faddr6') {
            setIsFAMobileValid(validateMobile(value));
        }
        if (name == 'caddr7') {
            setIsCAEmailValid(validateEmail(value));
        }
        if (name == 'faddr7') {
            setIsFAEmailValid(validateEmail(value));
        }

        setFormData((prevFormData) => ({
            ...prevFormData, // Keep the previous state
            enquiry: [
                {
                    ...prevFormData.enquiry[0], // Keep the existing properties of enquiry[0]
                    [name]: value, // Update the specific field
                },
            ],
        }));
       

        console.log("inpu = > check",formData);
    };

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const handleNext = () => {
        setValue((prevValue) => Math.min(prevValue + 1, 3)); // assuming there are 4 tabs (index 0 to 3)
    };

    // const handleAddRow = () => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         tableRows: [
    //             ...prevState.enquiry[0]?.tableRows,
    //             {
    //                 id: prevState.enquiry[0]?.tableRows.length + 1, // or generate a unique ID
    //                 enqdetid: 0,
    //                 desp: '',
    //                 mpnno: null,
    //                 qty: 0,
    //                 changedet: '',
    //                 reasonchange: '',
    //                 spltestcon: '',
    //                 casemodel: '',
    //                 enqecr: [{
    //                     enqid: 0,
    //                     enqdetid: 0,
    //                     ecrno: 0,
    //                     date: '',
    //                     casemodel: '',
    //                     changedet: '',
    //                     reasonchange: '',
    //                     spltestcon: '',
    //                     authempid: 0,
    //                     status: '',
    //                     cby: '',
    //                 }],
    //                 essentialTableRows: [
    //                     {
    //                         // essid: 0,
    //                         // enqdetid: 0,
    //                         essdatid: 1,
    //                         compliance: '',
    //                         attach: '',


    //                     }
    //                 ]
    //             }
    //         ]
    //     }));
    // };


    // const handleAddRow = () => {
    //     setFormData(prevState => {
    //         const currentTableRows = prevState.enquiry[0]?.tableRows || [];
    //         return {
    //             ...prevState,
    //             enquiry: [
    //                 {
    //                     ...prevState.enquiry[0],
    //                     tableRows: [
    //                         ...currentTableRows,
    //                         {
    //                             id: currentTableRows.length + 1, // or generate a unique ID
    //                             idenqdet: 0,
    //                             desp: '',
    //                             mpnno: null,
    //                             qty: 0,
    //                             changedet: '',
    //                             reasonchange: '',
    //                             spltestcon: '',
    //                             casemodel: '',
                            
    //                             essentialTableRows: [{
    //                                 enqdetid:0,
    //                                 essdatid: 1,
    //                                 compliance: '',
    //                                 attach: '',
    //                             }]
    //                         }
    //                     ]
    //                 }
    //             ]
    //         };
    //     });
    // };

    const handleAddRow = () => {
        setFormData(prevState => {
            const currentTableRows = prevState.enquiry[0]?.tableRows || [];
    
            // Determine the new id and idenqdet for the new row
            const newId = currentTableRows.length + 1;
            const newIdenqdet = newId;  // Assuming idenqdet follows the same numbering as id
    
            return {
                ...prevState,
                enquiry: [
                    {
                        ...prevState.enquiry[0],
                        tableRows: [
                            ...currentTableRows,
                            {
                                id: newId, // New row id
                                idenqdet: newIdenqdet, // New row idenqdet
                                desp: '',
                                mpnno: null,
                                qty: 0,
                                changedet: '',
                                reasonchange: '',
                                spltestcon: '',
                                casemodel: '',
                                essentialTableRows: [{
                                    
                                    enqdetid: newIdenqdet,  // Set enqdetid to match idenqdet of the new row
                                    essdatid: 1,
                                    compliance: '',
                                    attach: '',
                                }]
                            }
                        ]
                    }
                ]
            };
        });
    };
    
    
    

    const [values, setValues] = useState(0);

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
            setValues(values + 1);
        } else if (event.key === 'ArrowDown') {
            setValues(values - 1);
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setFormData(prevState => ({
            ...prevState,
            enquiry: [
                {
                    ...prevState.enquiry[0],
                    priority: newValue
                }
            ]
        }));
    };



    // const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     const { name, value } = event.target;
    //     const newTableRows = [...formData?.enquiry[0]?.tableRows];
    //     newTableRows[index][name] = value;
    //     setFormData({ ...formData.enquiry[0], tableRows: newTableRows });
    //     console.log(formData?.enquiry[0]?.tableRows, "formData.tableRows");
    // };

    // const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     const { name, value } = event.target;
    //     const updatedFormData = { ...formData };
    //     const newTableRows = [...updatedFormData.enquiry[0]?.tableRows];
    //     newTableRows[index] = {
    //         ...newTableRows[index],  
    //         [name]: value             
    //     };
    //     updatedFormData.enquiry[0].tableRows = newTableRows;
    //     setFormData(updatedFormData);
    //     console.log(updatedFormData?.enquiry[0]?.tableRows, "Updated tableRows");
    //     console.log(formData, "Updated => formData tableRows");
    // };

    const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const updatedFormData = { ...formData };
    
        // Copy the tableRows from formData
        const newTableRows = [...updatedFormData.enquiry[0]?.tableRows];
    
        // Update the specific row with new value
        newTableRows[index] = {
            ...newTableRows[index],
            [name]: value
        };
    
        // If this is a new row, set id and enqdetid based on previous row
        if (index > 0) {
            const previousRow = newTableRows[index - 1];
            newTableRows[index].id = previousRow.id + 1;
            newTableRows[index].idenqdet = previousRow.idenqdet + 1;
        }
    
        // Update the formData with modified tableRows
        updatedFormData.enquiry[0].tableRows = newTableRows;
        setFormData(updatedFormData);
    
        console.log(updatedFormData?.enquiry[0]?.tableRows, "Updated tableRows");
        console.log(formData, "Updated => formData tableRows");
    };



 
    const handleEssentialTableRowsChange = (
        rowid: number,
        index: number,
        tableRowindex: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        // Deep clone formData to ensure immutability
        const newEssentialTableRows = JSON.parse(JSON.stringify(formData));
        const { name, value } = event.target;
    
        // Get idenqdet from the parent table row (tableRowindex)
        const parentTableRow = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex];
        const enqdetid = parentTableRow?.idenqdet; // Get the idenqdet value from parent row
        console.log("enqdetid",enqdetid);
        
    
        if (name === "attach") {
            // Handle file upload
            if (event.target instanceof HTMLInputElement && event.target.files) {
                const file = event.target.files[0];
                const formdata = new FormData();
                formdata.append('file', file);
    
                apiService.uploadFiles(formdata)
                    .then(response => {
                        const url = response.data; // Assuming response.data contains the file URL
    
                        const res = {
                          
                            enqdetid: enqdetid, // Set enqdetid from parent row
                            essdatid: rowid,
                            compliance: "",   
                            attach: url,       
                        };
    
                        const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);
    
                        if (rowIndex === -1) {
                            // If the row doesn't exist, add it
                            newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
                        } else {
                            // If the row exists, update it
                            newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
                                ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
                                attach: url,
                            };
                        }
    
                        setFormData(newEssentialTableRows); // Update the state
                    })
                    .catch(err => {
                        console.log("File upload error", err);
                    });
            }
        } else {
            // Handle other input types (e.g., text)
            const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);
    
            const res = {
                enqdetid: enqdetid, // Set enqdetid from parent row
                essdatid: rowid,
                compliance: "",     // You can set a default value if needed
                attach: "",         // This field will be updated when needed
                [name]: value,      // Dynamically setting the field value based on the input name
            };
    
            if (rowIndex === -1) {
                // If the row doesn't exist, add it
                newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
            } else {
                // If the row exists, update it
                newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
                    ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
                    [name]: value,
                };
            }
    
            setFormData(newEssentialTableRows); // Update the state
        }
    
        // Log the updated formData for debugging
        console.log('Updated formData:', newEssentialTableRows);
    };
    
       // const handleEssentialTableRowsChange = (
    //     rowid: number,
    //     index: number,
    //     tableRowindex: number,
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // ) => {
    //     const newEssentialTableRows = { ...formData };
    //     const { name, value } = event.target;
    //     let url = "";
    //     if (name === "attach") {
    //         if (event.target instanceof HTMLInputElement && event.target.files) {
    //             const file = event.target.files[0];
    //             const formdata = new FormData();
    //             formdata.append('file', file);

    //             apiService.uploadFiles(formdata)
    //                 .then(response => {
    //                     url = response.data; // Assuming response.data contains the file URL

    //                     let res = {
    //                         requirements: 0,
    //                         essdatid: rowid,
    //                         attach: url, // Store the URL instead of the file object
    //                     };

    //                     const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

    //                     if (rowIndex === -1) {
    //                         // If the row doesn't exist, add it
    //                         newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //                     } else {
    //                         // If the row exists, update it
    //                         newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                             ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                             attach: url,
    //                         };
    //                     }

    //                     setFormData(newEssentialTableRows);
    //                 })
    //                 .catch(err => {
    //                     console.log("err", err);
    //                 });
    //         }
    //     } else {
    //         const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

    //         const res = {
    //             requirements: 0,
    //             essdatid: rowid,
    //             [name]: value,
    //         };

    //         if (rowIndex === -1) {
    //             // If the row doesn't exist, add it
    //             newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //         } else {
    //             // If the row exists, update it
    //             newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                 ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                 [name]: value,
    //             };
    //         }

    //         setFormData(newEssentialTableRows);
    //         console.log('newEssentialTableRows', newEssentialTableRows);
    //     }
    //     console.log(formData);
    // };

    // const handleEssentialTableRowsChange = (
    //     rowid: number,
    //     index: number,
    //     tableRowindex: number,
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // ) => {
    //     const newEssentialTableRows = { ...formData };
    //     const { name, value } = event.target;
    //     let url = "";

    //     if (name === "attach") {
    //         if (event.target instanceof HTMLInputElement && event.target.files) {
    //             const file = event.target.files[0];
    //             const formdata = new FormData();
    //             formdata.append('file', file);

    //             apiService.uploadFiles(formdata)
    //                 .then(response => {
    //                     url = response.data; // Assuming response.data contains the file URL

    //                     const res = {
    //                         essdatid: rowid,
    //                         compliance: "",   
    //                         attach: url,       
    //                     };

    //                     const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

    //                     if (rowIndex === -1) {
    //                         // If the row doesn't exist, add it
    //                         newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //                     } else {
    //                         // If the row exists, update it
    //                         newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                             ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                             attach: url,
    //                         };
    //                     }

    //                     setFormData(newEssentialTableRows);
    //                 })
    //                 .catch(err => {
    //                     console.log("err", err);
    //                 });
    //         }
    //     } else {
    //         const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

    //         const res={
    //                // Set to a valid enqdetid value
    //             compliance: "",     // Set to a default or appropriate value
    //             essdatid: 0,
    //             attach: "",        // This can be updated later if needed
    //             [name]: value,     // Set the value based on the input
    //             // Optional or set based on your logic
    //         };

    //         if (rowIndex === -1) {
    //             // If the row doesn't exist, add it
    //             newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //         } else {
    //             // If the row exists, update it
    //             newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                 ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                 [name]: value,
    //             };
    //         }

    //         setFormData(newEssentialTableRows);
    //         console.log('newEssentialTableRows', newEssentialTableRows);
    //     }
    //     console.log(formData);
    // };

    // const handleEssentialTableRowsChange = (
    //     rowid: number,
    //     index: number,
    //     tableRowindex: number,
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // ) => {
    //     // Deep clone formData to ensure immutability
    //     const newEssentialTableRows = JSON.parse(JSON.stringify(formData));
    //     const { name, value } = event.target;
    
    //     if (name === "attach") {
    //         // Handle file upload
    //         if (event.target instanceof HTMLInputElement && event.target.files) {
    //             const file = event.target.files[0];
    //             const formdata = new FormData();
    //             formdata.append('file', file);
    
    //             apiService.uploadFiles(formdata)
    //                 .then(response => {
    //                     const url = response.data; // Assuming response.data contains the file URL
    
    //                     const res = {
    //                         enqdetid:0,
    //                         essdatid: rowid,
    //                         compliance: "",   
    //                         attach: url,       
    //                     };
    
    //                     const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);
    
    //                     if (rowIndex === -1) {
    //                         // If the row doesn't exist, add it
    //                         newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //                     } else {
    //                         // If the row exists, update it
    //                         newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                             ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                             attach: url,
    //                         };
    //                     }
    
    //                     setFormData(newEssentialTableRows); // Update the state
    //                 })
    //                 .catch(err => {
    //                     console.log("File upload error", err);
    //                 });
    //         }
    //     } else {
    //         // Handle other input types (e.g., text)
    //         const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);
    
    //         const res = {
    //             enqdetid:0,
    //             essdatid: rowid,
    //             compliance: "",     // You can set a default value if needed
    //             attach: "",         // This field will be updated when needed
    //             [name]: value,      // Dynamically setting the field value based on the input name
    //         };
    
    //         if (rowIndex === -1) {
    //             // If the row doesn't exist, add it
    //             newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
    //         } else {
    //             // If the row exists, update it
    //             newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
    //                 ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
    //                 [name]: value,
    //             };
    //         }
    
    //         setFormData(newEssentialTableRows); // Update the state
    //     }
    
    //     // Log the updated formData for debugging
    //     console.log('Updated formData:', newEssentialTableRows);
    // };
    


    const handleInputChangesEcr = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;

        setFormData(prevState => {
            const updatedTableRows = [...prevState.enquiry[0]?.tableRows];

            // Update the selected row's field directly
            if (selectedRowdet !== null && selectedRowdet !== undefined) {
                updatedTableRows[selectedRowdet] = {
                    ...updatedTableRows[selectedRowdet],
                    [field]: value,
                };
            }

            return {
                ...prevState,
                tableRows: updatedTableRows,
            };
        });
    };




    const navigate = useNavigate();

    const handleCreateQuotation = () => {
        navigate('/crm/quotation', { state: { id: state.id } });
    };

    // const handleInputChangesEcr = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     const { value } = e.target;

    //     setFormData(prevState => {
    //         const updatedTableRows = [...prevState.tableRows];
    //         const updatedEnqecr = [...updatedTableRows[selectedRowdet]?.enqecr ?? []];

    //         // Update the correct field in the selected enqecr item
    //         updatedEnqecr[0] = {
    //             ...updatedEnqecr[0],
    //             [field]: value,
    //         };

    //         // Update the tableRows with the modified enqecr
    //         updatedTableRows[selectedRowdet] = {
    //             ...updatedTableRows[selectedRowdet],
    //             enqecr: updatedEnqecr,
    //         };

    //         return {
    //             ...prevState,
    //             tableRows: updatedTableRows,
    //         };
    //     });
    // };

    // const handleFileUpload = (
    //     e: React.ChangeEvent<HTMLInputElement>,
    //     index: number,
    //     tableRowIndex: number
    // ) => {
    //     if (e.target.files) {
    //         const fileName = e.target.files[0].name;
    //         console.log(fileName, 'fileName')
    //         const newEssentialTableRows = { ...formData.enquiry[0] };
    //         newEssentialTableRows.tableRows[tableRowIndex].essentialTableRows[index].attach = fileName;
    //         setFormData(newEssentialTableRows);
    //         console.log(newEssentialTableRows, "newEssentialTableRows with file attachment");
    //     }
    // };

    const [rows1, setRows1] = useState<{ fileName: string }[]>([]);


    const handleFileUpload1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // Loop through all selected files and add them to rows
            const newRows = Array.from(e.target.files).map(file => ({ fileName: file.name }));
            setRows1(prevRows => [...prevRows, ...newRows]);
        }
    };

    // Handle row deletion
    const handleDeleteRow = (index: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tableRows: prevFormData.enquiry[0]?.tableRows.filter((_, i) => i !== index)
        }));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpen1 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen1(true); // Open the dialog
    };

    const handleClickOpen2 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen2(true); // Open the dialog
    };
    const handleClickOpen3 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen3(true); // Open the dialog
    };
    const handleClose = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setSelectedItem(null);
    };
    const handleClose1 = () => {

        setOpen3(false);
        setSelectedItem(null);
    };
    const handlecancel = () => {
        navigate('/crm/enquiry');
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCustomerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerType(event.target.value);
        const { name, value } = event.target;
        if (selectedRow) {
            setSelectedRow(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleCustomerClick = () => {
        if (customerType === 'Ex-Customer') {
            setOpen(true);
        }
    };

    const handleDialogClose = () => {
        setOpen(false);
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
        setOpen(false);
    };

    const handleMoveToFactory = () => {
        setFormData(prevFormData => ({
            ...prevFormData, // Spread the entire previous formData
            enquiry: prevFormData.enquiry.map((item, index) =>
                index === 0 // Target the first item in the enquiry array
                    ? {
                        ...item, // Spread the rest of the item's data
                        faddr1: item.caddr1,
                        faddr2: item.caddr2,
                        faddr3: item.caddr3,
                        faddr4: item.caddr4,
                        faddr5: item.caddr5,
                        faddr6: item.caddr6,
                        faddr7: item.caddr7
                    }
                    : item
            )
        }));
    };

    const toaste = useLocation();

    useEffect(() => {
        // Type assertion to specify the expected shape of location.state
        const state = toaste.state as LocationState;

        if (state?.message) {
            toast.success(state.message);
        }
    }, [location.state]);


    const printRef = useRef(null);
    const [showImage, setShowImage] = useState(false);

    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Report",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => {
            setShowImage(false);
            console.log("after printing...");
        },
        removeAfterPrint: true,
    });

    return (
        <>
            <Helmet>
                <title>Update Lead</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Update Lead"

                    docs="https://material-ui.com/components/tabs/"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Card>
                            <Divider />
                            <CardContent>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        variant="scrollable"
                                        // scrollButtons="auto"
                                        // textColor="primary"
                                        // indicatorColor="primary"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        sx={{
                                            '& .MuiTab-root': {
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'blue', // Changes background color on hover
                                                    color: '#fff',
                                                },
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: 'Blue', // Background color for selected tab
                                                color: '#fff', // Keep the font color unchanged
                                            },
                                        }}
                                    >
                                        <Tab label="Basic Information" {...a11yProps(0)} />
                                        <Tab label="Address Details" {...a11yProps(1)} />
                                        <Tab label="Essential Data's" {...a11yProps(2)} />
                                        <Tab label="Follow Up" {...a11yProps(3)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        required
                                                                        id="enqNo"
                                                                        label="Enq No"
                                                                        name='enqNo'
                                                                        onClick={handleClickOpen}
                                                                        value={selectedRow ? selectedRow?.enqNo : formData?.enquiry[0]?.enqNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.name}
                                                                        helperText={errors.name}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="enqdate"
                                                                        label="Enq Date"
                                                                        name='enqdate'
                                                                        onClick={handleClickOpen}
                                                                        value={selectedRow ? new Date(selectedRow?.enqdate).toLocaleDateString('en-GB') : (formData?.enquiry[0]?.enqdate ? new Date(formData.enquiry[0]?.enqdate).toLocaleDateString('en-GB') : '')}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.name}
                                                                        helperText={errors.name}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="typeID"
                                                                        name="typeID"
                                                                        label="Type"
                                                                        InputProps={{ readOnly: true }}
                                                                        value={selectedRow ? selectedRow?.typeID : formData?.enquiry[0]?.typeID || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.typeID}
                                                                        helperText={errors.typeID}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    >
                                                                        {type.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        required
                                                                        id="name"
                                                                        label="Customer"
                                                                        name='name'
                                                                        onClick={handleClickOpen}
                                                                        value={selectedRow ? selectedRow?.name : formData?.enquiry[0]?.name || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.name}
                                                                        helperText={errors.name}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />


                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="modeId"
                                                                        label="Mode of Customer"
                                                                        name="modeId"
                                                                        value={selectedRow ? selectedRow?.modeId : formData?.enquiry[0]?.modeId || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                        error={!!errors.modeId}
                                                                        helperText={errors.modeId}

                                                                    >
                                                                        {mode.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        required
                                                                        // select
                                                                        id="divname"
                                                                        label="Division"
                                                                        name="divname"
                                                                        value={selectedRow ? selectedRow?.divname : formData?.enquiry[0]?.divname || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                        error={!!errors.divname}
                                                                        helperText={errors.divname}

                                                                    >
                                                                        {/* {division.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))} */}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="code"
                                                                        label="Code"
                                                                        name="code"
                                                                        autoFocus
                                                                        value={selectedRow ? selectedRow?.code : formData?.enquiry[0]?.code || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                    />
                                                                    <TextField
                                                                        id="sname"
                                                                        label="Short Name"
                                                                        // autoFocus
                                                                        name="sname"
                                                                        value={selectedRow ? selectedRow?.sname : formData?.enquiry[0]?.sname || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                    >

                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="city"
                                                                        label="Place"
                                                                        name="city"
                                                                        value={selectedRow ? selectedRow?.city : formData?.enquiry[0]?.city || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 11 }}
                                                                    >
                                                                        {city.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.sname}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="ceo"
                                                                        label="Managing Director"
                                                                        name="ceo"
                                                                        value={selectedRow ? selectedRow?.ceo : formData?.enquiry[0]?.ceo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />

                                                                    <TextField
                                                                        id="email"
                                                                        label="Email ID"
                                                                        name="email"
                                                                        value={selectedRow ? selectedRow?.email : formData?.enquiry[0]?.email || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                        error={!!errors.email}
                                                                        helperText={errors.email}
                                                                    />

                                                                    <TextField
                                                                        id="pmobile"
                                                                        label=" Mobile Number"
                                                                        name="pmobile"
                                                                        value={selectedRow ? selectedRow?.pmobile : formData?.enquiry[0]?.pmobile || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isPMobileValid}
                                                                        helperText={!isPMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 10 }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="gstno"
                                                                        label="GST No."
                                                                        name="gstno"
                                                                        value={selectedRow ? selectedRow?.gstno : formData?.enquiry[0]?.gstno || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.gstno}
                                                                        helperText={errors.gstno}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />


                                                                    <TextField
                                                                        id="panno"
                                                                        label="PAN No"
                                                                        name="panno"
                                                                        value={selectedRow ? selectedRow?.panno : formData?.enquiry[0]?.panno || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.panno}
                                                                        helperText={errors.panno}
                                                                        inputProps={{ maxLength: 20 }}
                                                                    />
                                                                    <TextField
                                                                        id="tinno"
                                                                        label="TIN No"
                                                                        name="tinno"
                                                                        value={selectedRow ? selectedRow?.tinno : formData?.enquiry[0]?.tinno || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />

                                                                    <TextField
                                                                        id="rfxEnqNo"
                                                                         label="Customer Ref.No"
                                                                        name="rfxEnqNo"
                                                                        value={selectedRow ? selectedRow?.rfxEnqNo : formData?.enquiry[0]?.rfxEnqNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />
                                                                    <TextField
                                                                        id="dueon"
                                                                        label="Due On"
                                                                        type="date"
                                                                        InputLabelProps={{ shrink: true }}
                                                                        name="dueOn"
                                                                        value={selectedRow ? selectedRow.dueOn.split('T')[0] : formData?.enquiry[0]?.dueOn.split('T')[0] || ''}
                                                                        onChange={handleInputChanges}

                                                                    />

                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Contact Person For Commercial" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        id="ccperson"
                                                                        label="Contact Person"
                                                                        name="ccperson"
                                                                        value={selectedRow ? selectedRow?.ccperson : formData?.enquiry[0]?.ccperson || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.ccperson}
                                                                        helperText={errors.ccperson}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="cdesign"
                                                                        label="Designation"
                                                                        name="cdesign"
                                                                        value={selectedRow ? selectedRow?.cdesign : formData?.enquiry[0]?.cdesign || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.cdesign}
                                                                        helperText={errors.cdesign}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="cmobile"
                                                                        label="Mobile No"
                                                                        name="cmobile"
                                                                        value={selectedRow ? selectedRow?.cmobile : formData?.enquiry[0]?.cmobile || ''}
                                                                        onChange={handleInputChanges}

                                                                        error={!!errors.cmobile}
                                                                        helperText={errors.cmobile}
                                                                        inputProps={{ maxLength: 10 }}
                                                                    />
                                                                    <TextField
                                                                        id="cmail"
                                                                        label="Email ID"
                                                                        name="cmail"
                                                                        value={selectedRow ? selectedRow?.cmail : formData?.enquiry[0]?.cmail || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.cmail}
                                                                        helperText={errors.cmail}
                                                                        inputProps={{ maxLength: 45 }}

                                                                    />


                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title=" Contact Person For Technical" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        id="tcperson"
                                                                        label="Contact Person"
                                                                        name="tcperson"
                                                                        value={selectedRow ? selectedRow?.tcperson : formData?.enquiry[0]?.tcperson || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tcperson}
                                                                        helperText={errors.tcperson}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="tdesign"
                                                                        label="Designation"
                                                                        name="tdesign"
                                                                        value={selectedRow ? selectedRow?.tdesign : formData?.enquiry[0]?.tdesign || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tdesign}
                                                                        helperText={errors.tdesign}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="tmobile"
                                                                        label="Mobile No"
                                                                        name="tmobile"
                                                                        value={selectedRow ? selectedRow?.tmobile : formData?.enquiry[0]?.tmobile || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tmobile}
                                                                        helperText={errors.tmobile}
                                                                        inputProps={{ maxLength: 10 }}

                                                                    />
                                                                    <TextField
                                                                        id="tmail"
                                                                        label="Email ID"
                                                                        name="tmail"
                                                                        value={selectedRow ? selectedRow?.tmail : formData?.enquiry[0]?.tmail || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tmail}
                                                                        helperText={errors.tmail}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    />
                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Bank Information" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="bankName"
                                                                        label="Bank Name"
                                                                        name="bankName"
                                                                        value={selectedRow ? selectedRow?.bankName : formData?.enquiry[0]?.bankName || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}

                                                                    />
                                                                    <TextField
                                                                        id="branchname"
                                                                        label="Branch Name"
                                                                        name="branchname"
                                                                        value={selectedRow ? selectedRow?.branchname : formData?.enquiry[0]?.branchname || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}

                                                                    />
                                                                    <TextField
                                                                        id="accNo"
                                                                        label="Account No"
                                                                        name="accNo"
                                                                        value={selectedRow ? selectedRow?.accNo : formData?.enquiry[0]?.accNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="ifsc"
                                                                        label="IFSC Code"
                                                                        sx={{ height: '10ch' }}
                                                                        name="ifsc"
                                                                        value={selectedRow ? selectedRow?.ifsc : formData?.enquiry[0]?.ifsc || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />

                                                                </div>


                                                            </Box>

                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 95, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box>  </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Contact Address" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="caddr1"
                                                                        label="Plot No/Street"
                                                                        name="caddr1"
                                                                        value={formData?.enquiry[0]?.caddr1 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="caddr2"
                                                                        label="City"
                                                                        name="caddr2"
                                                                        value={formData?.enquiry[0]?.caddr2 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="caddr3"
                                                                        label="District"
                                                                        name="caddr3"
                                                                        value={formData?.enquiry[0]?.caddr3 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="caddr4"
                                                                        label="State"
                                                                        name="caddr4"
                                                                        value={formData?.enquiry[0]?.caddr4 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="caddr5"
                                                                        label="Pincode"
                                                                        name="caddr5"
                                                                        value={formData?.enquiry[0]?.caddr5 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="caddr6"
                                                                        label="Landline"
                                                                        name="caddr6"
                                                                        value={formData?.enquiry[0]?.caddr6 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isCAMobileValid}
                                                                        helperText={!isCAMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 10 }}
                                                                    />
                                                                    {/* <TextField
                                                                        id="caddr7"
                                                                        label="Email"
                                                                        name="caddr7"
                                                                        value={formData?.enquiry[0]?.caddr7 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isCAEmailValid}
                                                                        helperText={!isCAEmailValid ? 'Invalid email id' : ''}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    /> */}
                                                                    <Button
                                                                        sx={{ margin: 1 }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleMoveToFactory}
                                                                    >
                                                                        Copy as Contact Address
                                                                    </Button>

                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Factory Address" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="faddr1"
                                                                        label="Plot No/Street"
                                                                        name="faddr1"
                                                                        value={formData?.enquiry[0]?.faddr1 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="faddr2"
                                                                        label="City"
                                                                        name="faddr2"
                                                                        value={formData?.enquiry[0]?.faddr2 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        id="faddr3"
                                                                        label="District"
                                                                        name="faddr3"
                                                                        value={formData?.enquiry[0]?.faddr3 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="faddr4"
                                                                        label="State"
                                                                        name="faddr4"
                                                                        value={formData?.enquiry[0]?.faddr4 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="faddr5"
                                                                        label="Pincode"
                                                                        name="faddr5"
                                                                        value={formData?.enquiry[0]?.faddr5 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="faddr6"
                                                                        label="Landline"
                                                                        name="faddr6"
                                                                        value={formData?.enquiry[0]?.faddr6 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isFAMobileValid}
                                                                        helperText={!isFAMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 10 }}
                                                                    />
                                                                    {/* <TextField
                                                                        id="faddr7"
                                                                        label="Email"
                                                                        name="faddr7"
                                                                        value={formData?.enquiry[0]?.faddr7 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isFAEmailValid}
                                                                        helperText={!isFAEmailValid ? 'Invalid email id' : ''}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    /> */}

                                                                </div>
                                                            </Box>
                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 95, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box></CardContent>
                                                    </Card>
                                                </Grid>

                                            </Grid>

                                        </form>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {/* <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>

                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        required
                                                                        id="basicValue"
                                                                        label="Basic Value"
                                                                        type="number"
                                                                        name="basicValue"
                                                                        value={formData?.enquiry[0]?.basicValue}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }}
                                                                        // onChange={handleInputChanges}
                                                                        onChange={handleInputChanges}
                                                                        onKeyDown={handleKeyDown}
                                                                        sx={{ m: 1, width: '24ch' }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </form>

                                            </Grid>
                                        </Grid>
                                        <br /> */}
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>
                                                        <CardHeader title="Customer Requirements" />
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>S.No</TableCell>
                                                                            <TableCell>MPN No</TableCell>
                                                                            <TableCell>Description</TableCell>
                                                                            <TableCell>Qty</TableCell>
                                                                            <TableCell>  </TableCell>
                                                                            <TableCell>  </TableCell>
                                                                            <TableCell>Delete</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {formData.enquiry[0]?.tableRows.slice().map((detail: any, index: number) => (
                                                                            <TableRow key={detail.id}>
                                                                                <TableCell>{index + 1}</TableCell>
                                                                                <TableCell>{detail.mpnno}</TableCell>
                                                                                <TableCell>
                                                                                    <TextField
                                                                                        id='desp'
                                                                                        multiline
                                                                                        name="desp"
                                                                                        value={detail.desp}
                                                                                        onChange={(e) => handleTableRowsChange(index, e)}
                                                                                        inputProps={{ maxLength: 250 }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <TextField
                                                                                        id='qty'
                                                                                        type="number"
                                                                                        name="qty"
                                                                                        value={detail.qty}
                                                                                        onChange={(e) => handleTableRowsChange(index, e)}
                                                                                        inputProps={{ maxLength: 11 }}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell>
                                                                                    <Button onClick={() => handleClickOpen1(index)}>Essential's Data</Button>
                                                                                </TableCell>

                                                                                <TableCell>
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        onClick={() => handleDeleteRow(index)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>

                                                                </Table>

                                                            </TableContainer>
                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 95, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box>

                                                        </Paper>
                                                        {/* <Button   variant="contained" color="primary">Add Row</Button> */}

                                                    </Card>

                                                </form></Grid>
                                            <Box display="flex" justifyContent="flex-end" p={2}>
                                                <Button sx={{ position: 'absolute', right: 40, bottom: 155, margin: 1 }} variant='contained' color="primary" onClick={handleAddRow}>
                                                    <FontAwesomeIcon icon={faPlus} />  Add More
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        <Grid
                                            container
                                            direction="row" // Stack items vertically
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={2} // Adjust spacing between items
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>
                                                        <CardHeader title="Next Step" />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <Grid container direction="row" spacing={2}>
                                                                    <Grid item>
                                                                        <Grid container direction="column" spacing={2}>
                                                                            <Card>
                                                                                <Grid item>
{console.log(formData?.enquiry[0],"formData?.enquiry[0]")
}
                                                                                    <Tabs
                                                                                        value={formData?.enquiry[0].priority || ""}
                                                                                        onChange={handleTabChange}
                                                                                        aria-label="priority tabs"
                                                                                        variant="fullWidth"
                                                                                        sx={{
                                                                                            m: 1, width: '24ch',
                                                                                            '& .MuiTab-root': {
                                                                                                cursor: 'pointer',
                                                                                                '&:hover': {
                                                                                                    backgroundColor: 'blue', // Changes background color on hover
                                                                                                    color: '#fff',
                                                                                                },
                                                                                            },
                                                                                            '& .Mui-selected': {
                                                                                                backgroundColor: 'Blue', // Background color for selected tab
                                                                                                color: '#fff', // Keep the font color unchanged
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {priority.map((item) => (
                                                                                            <Tab
                                                                                                key={item.id} // Assuming there's an `id` field in your priority data
                                                                                                label={item.name} // Display the priority name
                                                                                                value={item.name} // Set the tab value based on the name
                                                                                                style={formData?.enquiry[0].priority === item.name ? tabStyles[item.name.toLowerCase()] : undefined} // Use dynamic styling based on the name
                                                                                            />
                                                                                        ))}
                                                                                    </Tabs>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        required
                                                                                        select
                                                                                        id="followtype"
                                                                                        label="Follow Type"
                                                                                        name="followtype"
                                                                                        value={formData?.enquiry[0]?.followtype}
                                                                                        onChange={handleInputChanges}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                        error={!!errors.followtype}
                                                                                        helperText={errors.followtype}
                                                                                        inputProps={{ maxLength: 11 }}
                                                                                    >
                                                                                        {follow.map((option) => (
                                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                                {option?.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        id="followdesc"
                                                                                        label="Description"
                                                                                        multiline
                                                                                        name="followdesc"
                                                                                        value={formData.enquiry[0]?.followdesc}
                                                                                        onChange={handleInputChanges}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                        error={!!errors.followdesc}
                                                                                        helperText={errors.followdesc}
                                                                                        inputProps={{ maxLength: 250 }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        id="nextfollow"
                                                                                        label="Next Follow Date"
                                                                                        type="date"
                                                                                        name="nextfollow"
                                                                                        value={formData.enquiry[0]?.nextfollow}
                                                                                        onChange={handleInputChanges}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        error={!!errors.nextfollow}
                                                                                        helperText={errors.nextfollow}

                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        required
                                                                                        select
                                                                                        id="status"
                                                                                        label="Status"
                                                                                        name="statusId"
                                                                                        value={formData?.enquiry[0]?.statusId}
                                                                                        onChange={handleInputChanges}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                        error={!!errors.statusId}
                                                                                        helperText={errors.statusId}
                                                                                        inputProps={{ maxLength: 11 }}
                                                                                    >
                                                                                        {status.map((option) => (
                                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                                {option?.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                            </Card>
                                                                        </Grid>

                                                                    </Grid>

                                                                    <Grid item xs>
                                                                        <Card>
                                                                            <CardHeader title="Follow History" />
                                                                            <Paper>
                                                                                <TableContainer>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell>S.No</TableCell>
                                                                                                <TableCell>Follow Type</TableCell>
                                                                                                <TableCell>Follow Date</TableCell>
                                                                                                <TableCell>Description</TableCell>
                                                                                                <TableCell>Priority</TableCell>
                                                                                                <TableCell>Next Follow</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {formData.enquiry[0]?.enqfollow.slice().reverse().map((data: any, index: number) => (
                                                                                                <TableRow key={data.id}>
                                                                                                    <TableCell>{data.id}</TableCell> {/* Serial number */}
                                                                                                    <TableCell>{data.ftypename}</TableCell> {/* Follow Type */}
                                                                                                    <TableCell>{data.fdate_s}</TableCell> {/* Follow Date */}
                                                                                                    <TableCell>{data.desp}</TableCell> {/* Description */}
                                                                                                    <TableCell>{data.priority}</TableCell> {/* Priority */}
                                                                                                    <TableCell>{data.nfdate_s}</TableCell> {/* Next Follow */}
                                                                                                </TableRow>
                                                                                            ))}
                                                                                        </TableBody>

                                                                                    </Table>
                                                                                </TableContainer>
                                                                              
                                                                            </Paper>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>

                                                            </Box>
                                                        </CardContent>
                                                    </Card>


                                                </form>



                                            </Grid>


                                        </Grid>

                                        <br />

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                             

                                                            <Box display="flex" justifyContent="flex-end" p={2}>
                                                                <Button sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }} onClick={handleSubmit} variant="contained" color="primary">
                                                                    Update
                                                                </Button>
                                                            </Box>

                                                    

                                                  

                                                </form></Grid>

                                        </Grid>
                                        {/* <Button sx={{ margin: 1 }} variant='contained' color="primary" onClick={() => handleQuotation()}> */}
                                        {/* Add More
                                        </Button> */}
                                        <br />


                                    </TabPanel>
                                </Box>
                                {/* <Dialog className="dialog" open={open} onClose={
                                }
                                    maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                                    fullWidth
                                    sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                                    <form>
                                        <DialogTitle>Ex - Customer</DialogTitle>
                                        <DialogContent>
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="tableHead" >S.No</TableCell>
                                                                <TableCell className="tableHead"> Code</TableCell>
                                                                <TableCell className="tableHead">Name</TableCell>
                                                                <TableCell className="tableHead">Place</TableCell>
                                                                <TableCell className="tableHead">Mobile	</TableCell>
                                                                <TableCell className="tableHead">Mail</TableCell>
                                                                <TableCell className="tableHead">Contact Person</TableCell>
                                                                <TableCell className="tableHead">Is Major</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody >
                                                            {rowdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                                <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                                                                    <TableCell className="tableBody">{page * rowsPerPage + index + 1}</TableCell>
                                                                    <TableCell className="tableBody">{row.code}</TableCell>
                                                                    <TableCell className="tableBody">{row.name}</TableCell>
                                                                    <TableCell className="tableBody">{row.place}</TableCell>
                                                                    <TableCell className="tableBody">{row.mobile}</TableCell>
                                                                    <TableCell className="tableBody">{row.mail}</TableCell>
                                                                    <TableCell className="tableBody">{row.contactPerson}</TableCell>
                                                                    <TableCell className="tableBody">{row.isMajor ? 'Yes' : 'No'}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    component="div"
                                                    count={data.length === 0 ? 0 : data[0]["count"]}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                />
                                            </Paper>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Cancel
                                            </Button>
                                            <Button color="primary" >
                                                {selectedItem ? 'Update' : 'Save'}
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Dialog> */}
                                <Dialog
                                    className="dialog"
                                    open={open1}
                                    onClose={handleClose}
                                    maxWidth="lg"
                                    fullWidth
                                    sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}
                                >
                                    <form>
                                        <DialogTitle>Essential Data</DialogTitle>
                                        <DialogContent>
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>S.No</TableCell>
                                                                <TableCell>SAARC Requirements</TableCell>
                                                                <TableCell>Customer Compliance</TableCell>
                                                                <TableCell>Attachments</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {essdata.map((row, index) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell ><Typography>{row.id}</Typography></TableCell>
                                                                    <TableCell>{row.name}</TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="compliance"
                                                                            label="Compliance"
                                                                            multiline
                                                                            name="compliance"
                                                                            value={formData.enquiry[0]?.tableRows[selectedRowdet]?.essentialTableRows.find(t => t.essdatid === row.id)?.compliance || ""}
                                                                            inputProps={{ maxLength: 250 }}
                                                                            onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                            sx={{ m: 1, width: '24ch' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.isattach === 1 ? (
                                                                            <div>
                                                                                <input
                                                                                    type="file"
                                                                                    name="attach"
                                                                                    id="attach"

                                                                                    onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                                    style={{ margin: '10px' }}
                                                                                />
                                                                                {/* Display the URL as a clickable link */}
                                                                                <a
                                                                                    href={formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows[index]?.attach}
                                                                                    target="_blank"   // Opens the link in a new tab
                                                                                    rel="noopener noreferrer"  // Prevents the new page from having access to the original window object
                                                                                >
                                                                                    View File
                                                                                </a>

                                                                            </div>
                                                                        ) : (
                                                                            <TableCell>
                                                                                {/* <TextField disabled /> */}
                                                                            </TableCell>
                                                                        )}
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Dialog>


                                {/* <Button sx={{ margin: 1 }} onClick={handleSubmit} variant='contained' color="primary">
                                    Save
                                </Button> */}
                                <Button sx={{ margin: 1 }} variant='contained' color="warning" onClick={handlecancel}>
                                    Cancel
                                </Button>


                            </CardContent>
                        </Card>

                    </Grid>

                </Grid>


            </Container >
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default UpdateLead;

const tabStyles = {
    hot: {
        backgroundColor: 'red',
        color: 'white',
    },
    warm: {
        backgroundColor: 'orange',
        color: 'white',
    },
    cold: {
        backgroundColor: 'green',
        color: 'white',
    },
};


