
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Accordion, Button, Col, Container, Row, Table } from 'react-bootstrap';
import { SyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart, faCircleRight, faCommentDots, faCube, faFaceGrinTongueSquint, faFileArchive, faHandPointDown, faHandPointUp, faICursor, faLayerGroup, faLineChart, faList, faListCheck, faMessage, faPieChart, faQuoteLeftAlt, faWonSign } from '@fortawesome/free-solid-svg-icons';
import { Box, colors, Grid, Paper, Tab, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material';
import { FaEmpire, FaRegMessage } from 'react-icons/fa6';
import { Padding } from '@mui/icons-material';

type Props = {}

// Register the required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

// Register the necessary chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ChartDataLabels,
    //Filler,  // Required for the area fill effect
);

// Register required Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, ChartDataLabels);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
export default function CrmDashboard({ }: Props) {
    const [isExpanded, setIsExpanded] = useState(true);
    //Pie chart
    const data = {
        labels: ['Hot', 'Warm', 'Cold'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3],
                backgroundColor: [
                    '#ff625f',
                    '#384647',
                    '#03aaa2',


                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderColor: [
                    '#384647',
                    '#03aaa2',

                    '#ff625f',
                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top' as const,
    //         },
    //         tooltip: {
    //             enabled: true,
    //         },
    //     },
    // };

    const options = {
        plugins: {
            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return `${label}: ${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: false,
                position: 'top' as const,
            },
        },
    };
    const optionssource = {
        plugins: {
            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return ` ${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: true,
                position: 'top' as const,
            },
        },
    };

    // Doughnut 
    const data2 = {
        labels: ['Hot', 'Warm', 'Cold'],
        datasets: [
            {
                label: 'Priority',
                data: [12, 19, 3],
                backgroundColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',


                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',

                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderWidth: 1,
            },
        ],
    };
    const dataType = {
        labels: ['New', 'Ex-Customer', 'Repeat'],
        datasets: [
            {
                label: 'Leads Count',
                data: [12, 19, 3],
                backgroundColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',


                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',

                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderWidth: 1,
            },
        ],
    };
    const dataSource = {
        labels: ['Mail-ID', 'Tender', 'Expo', 'Phone Call', 'Direct Visit'],
        datasets: [
            {
                label: 'Leads Count',
                data: [12, 19, 3, 6, 10],
                backgroundColor: [
                    '#008779',

                ],
                borderColor: [
                    '#008779',
                ],
                borderWidth: 1,
            },
        ],
    };
    const dataDealSource = {
        labels: ['Mail-ID', 'Tender', 'Expo', 'Phone Call', 'Direct Visit'],
        datasets: [
            {
                label: 'Deals Count',
                data: [12, 19, 3, 6, 10],
                backgroundColor: [
                    '#008779',

                ],
                borderColor: [
                    '#008779',
                ],
                borderWidth: 1,
            },
        ],
    };
    //barcahrt

    //Stocked Bar  //Doughnut Stages

    const data4 = {
        labels: ['Potential', 'Interested', 'Qualified', 'Ex-Customer', 'Not Interested', 'Bad Fit', 'Convert To Enquiry', 'Canceled'],
        datasets: [
            {
                label: 'Leads Count',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: '#008779',
            },
            // {
            //     label: 'Dataset 2',
            //     data: [5, 10, 8, 12, 6, 7],
            //     backgroundColor: '#384647',
            // },
            // {
            //     label: 'Dataset 3',
            //     data: [2, 3, 10, 15, 9, 12],
            //     backgroundColor: '#ff625f',
            // },
        ],
    };

    const dataDealStatus = {
        labels: ['P0s Enquiry Received', 'P1-Q Budgetary Quote', 'P1-QTN Quotation', 'P2 Tender Submitted', 'P3 Under Negotiation', 'P4 Order Receipt', 'L1 OL -Price High', 'L2 OL - Technically Rejected'],
        datasets: [
            {
                label: 'Deals Count',
                data: [12, 19, 3, 5, 2, 3, 7, 15],
                backgroundColor: '#008779',
            },
            // {
            //     label: 'Dataset 2',
            //     data: [5, 10, 8, 12, 6, 7],
            //     backgroundColor: '#384647',
            // },
            // {
            //     label: 'Dataset 3',
            //     data: [2, 3, 10, 15, 9, 12],
            //     backgroundColor: '#ff625f',
            // },
        ],
    };


    const options4 = {

        responsive: true,
        maintainAspectRatio: false,
        // plugins: {
        //     legend: {
        //         position: 'top' as const,
        //     },
        //     title: {
        //         display: false,
        //         text: 'Stacked Bar Chart Example',
        //     },
        // },
        plugins: {

            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return `${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: true,
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                stacked: true,  // Enable stacking on x-axis
            },
            y: {
                stacked: true,  // Enable stacking on y-axis
                beginAtZero: true,  // Ensures the y-axis starts at 0
            },
        },
    };
    //Area Line Chart
    const dataAreaLine = {
        labels: ['Apl', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'],
        datasets: [
            {
                label: 'Target',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,  // Fills the area below the line
                borderColor: '#ff6384',
                backgroundColor: '#ff6384',
                tension: 0.4,  // Smooth curve effect
            },
            {
                label: 'Generated',
                data: [45, 49, 60, 21, 36, 62, 54],
                fill: false,  // Fills the area below the line
                borderColor: '#36a2eb',
                backgroundColor: '#36a2eb',
                tension: 0.4,  // Smooth curve effect
            },
            {
                label: 'Converted',
                data: [10, 19, 16, 11, 16, 12, 20],
                fill: false,  // Fills the area below the line
                borderColor: '#4bc0c0',
                backgroundColor: '#4bc0c0',
                tension: 0.4,  // Smooth curve effect
            },
        ],
    };
    const optionsAreaLine = {
        plugins: {
            datalabels: {
                display: true,
                align: 'top' as const,
                backgroundColor: '#ddd',
                color: "#000",
                borderRadius: 3,
                font: {
                    size: 10,
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    //Stocked Bar
    const dataStockedbar = {
        labels: ['New', 'Ex-Customer', 'Repeat'],
        datasets: [
            {
                label: 'Generated',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: '#ff6384',
            },
            {
                label: 'Opportunity',
                data: [35, 49, 60, 91, 46, 75, 30],
                backgroundColor: '#36a2eb',
            },
            {
                label: 'Converted',
                data: [25, 39, 40, 61, 36, 85, 20],
                backgroundColor: '#4bc0c0',
            },
        ],
    };

    const optionsStockedbar = {
        responsive: true,
        plugins: {
            datalabels: {

                color: "#fff",
            },
            legend: {
                position: 'top' as const,
            },
            //   title: {
            //     display: true,
            //     // text: 'Stacked Bar Chart Example',
            //   },
        },
        scales: {
            x: {
                stacked: true, // Stacking on the x-axis
            },
            y: {
                stacked: true, // Stacking on the y-axis
                beginAtZero: true,
            },
        },
    };

    const handleClick = () => {

        // setIsExpanded(!isExpanded);
    };
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const rowpad = {
        Padding: "0px", // camelCase

    };
    return (
        <>
            <Tabs style={{ marginTop: "15px;", marginLeft: "10px" }}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                    '& .MuiTab-root': {
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'blue', // Changes background color on hover
                            color: '#fff',
                        },
                    },
                    '& .Mui-selected': {
                        backgroundColor: 'Blue', // Background color for selected tab
                        color: '#fff', // Keep the font color unchanged
                    },
                }}
            >
                <Tab label="Leads" {...a11yProps(0)} />
                <Tab label="Deals(Enquiries)" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid xs={12} md={5} style={{ paddingLeft: "10px" }}>
                        <Box p={0}>
                            <div className='divpanal'>
                                <div className='dpheader' onClick={handleClick} style={{ textAlign: "center" }}>
                                    <label><span className=''><FontAwesomeIcon icon={faCommentDots} /></span> Leads Follow</label>
                                </div>
                                {isExpanded && (
                                    <div className='dpmain'>
                                        <Grid container className='dpbox'>
                                            <Grid className='dpgrid' xs={12} md={2.2} style={{ paddingLeft: "0px" }}>
                                                <label>Pending</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.2} style={{ paddingLeft: "px" }}>
                                                <label>Today</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.6} style={{ paddingLeft: "0px" }}>
                                                <label>Upcoming</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.6} style={{ paddingLeft: "0px" }}>
                                                <label>Opportunity</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.4} style={{ paddingLeft: "0px" }}>
                                                <label>Converted</label><br />
                                                <span>20</span>
                                            </Grid>
                                            {/* <Grid className='dpgrid' xs={12} md={2} style={{ paddingLeft: "10px" }}>
                                                <label>Rejected</label><br />
                                                <span>20</span>
                                            </Grid> */}
                                        </Grid>
                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "448px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Lead Statuses</label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '340px', marginLeft: "15px" }}>
                                        <Bar data={data4} options={options4} />

                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "442px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faLineChart} /></span> Lead Generation Current Year <span>24-25</span></label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '400px', marginLeft: "15px" }}>
                                        <Line data={dataAreaLine} options={optionsAreaLine} height={250} />

                                    </div>
                                )}

                            </div>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={7} style={{}}>
                        <Grid container>
                            <Grid xs={12} md={5} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "292px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faPieChart} /></span> Leads Priority</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '220px', height: '220px', marginLeft: "24px", marginTop: "9px" }}>
                                                <Doughnut data={data2} options={options}>
                                                </Doughnut>

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={7} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Lead Sources</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '300px', height: '247px', marginLeft: "25px" }}>
                                                <Bar data={dataSource} options={options4} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={12} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Today Followup Leads </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{}}>
                                                <Paper>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow >
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Lead No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Customer</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                <TableRow sx={{
                                                                    backgroundColor: "#f9f9f9"  // Adds alternating background colors
                                                                }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>1</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>Lead/01</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Adani Power</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "Potential" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow sx={{
                                                                    backgroundColor: "#fff"  // Adds alternating background colors
                                                                }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>2</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>Lead/02</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Indian Gas</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "Interested" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow sx={{
                                                                    backgroundColor: "#f9f9f9"  // Adds alternating background colors
                                                                }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>3</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>Lead/03</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Tata Steel</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "Qualified" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow sx={{
                                                                    backgroundColor: "#fff"  // Adds alternating background colors
                                                                }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>4</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>Lead/04</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>National Aluminium</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "Not Interested" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow sx={{
                                                                    backgroundColor: "#f9f9f9"  // Adds alternating background colors
                                                                }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>5</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>Lead/05</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>IFCI Ltd </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'green', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "Bad Fit" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>

                            </Grid>

                            <Grid xs={12} md={4} style={{}}>

                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "440px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Opportunity Leads </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain'>
                                                <Paper style={{ height: "395px" }}>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow >
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Name</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Leads</TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>1</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Suganthi.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Suganthi</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>20</label> </TableCell>

                                                                </TableRow>
                                                                <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>1</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Sridevi.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Sri Devi</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>12</label> </TableCell>

                                                                </TableRow>
                                                                <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>1</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Christy.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Christy</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>7</label> </TableCell>

                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "442px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Leads Generation Type</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '350px', height: '350px', marginLeft: "38px", marginTop: "9px" }}>
                                                <Bar data={dataStockedbar} options={optionsStockedbar} width={350} height={350} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>



                </Grid>
            </TabPanel >
            {/* Deal Enq */}
            < TabPanel value={value} index={1} >
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid xs={12} md={5} style={{ paddingLeft: "10px" }}>
                        <Box p={0}>
                            <div className='divpanal'>
                                <div className='dpheader' onClick={handleClick} style={{ textAlign: "center" }}>
                                    <label><span className=''><FontAwesomeIcon icon={faCommentDots} /></span> Deals Follow</label>
                                </div>
                                {isExpanded && (
                                    <div className='dpmain'>
                                        <Grid container className='dpbox'>
                                            <Grid className='dpgrid' xs={12} md={2.2} style={{ paddingLeft: "0px" }}>
                                                <label>Pending</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.2} style={{ paddingLeft: "px" }}>
                                                <label>Today</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.6} style={{ paddingLeft: "0px" }}>
                                                <label>Upcoming</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.6} style={{ paddingLeft: "0px" }}>
                                                <label>Opportunity</label><br />
                                                <span>20</span>
                                            </Grid>
                                            <Grid className='dpgrid' xs={12} md={2.4} style={{ paddingLeft: "0px" }}>
                                                <label>Won Order</label><br />
                                                <span>20</span>
                                            </Grid>
                                            {/* <Grid className='dpgrid' xs={12} md={2} style={{ paddingLeft: "10px" }}>
                                                <label>Rejected</label><br />
                                                <span>20</span>
                                            </Grid> */}
                                        </Grid>
                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "442px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Deal Statuses</label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '340px', marginLeft: "15px" }}>
                                        <Bar data={dataDealStatus} options={options4} />

                                    </div>
                                )}

                            </div>
                        </Box>

                    </Grid>
                    <Grid xs={12} md={7} style={{}}>
                        <Grid container>
                            <Grid xs={12} md={5} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "292px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faPieChart} /></span> Deals Priority</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '220px', height: '220px', marginLeft: "24px", marginTop: "9px" }}>
                                                <Doughnut data={data2} options={options}>
                                                </Doughnut>

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={7} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Deal Sources</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '300px', height: '247px', marginLeft: "25px" }}>
                                                <Bar data={dataDealSource} options={options4} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={12} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Today Followup Deals </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{}}>
                                                <Paper>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Deal No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Customer</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>1</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>SC/Adani-Chennai/24-25/1</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Adani Power</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "P0s Enquiry Received" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>2</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>SC/IndGas-CBE/24-25/2</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Indian Gas</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "P1-Q Budgetary Quote" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>3</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>SC/TataSteel-Delhi/24-25/3</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Tata Steel</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "P2 Tender Submitted" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>4</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>SC/NATALM-Pune/24-25/4</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>National Aluminium</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "#606060" : 'red', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "P3 Under Negotiation" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>5</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>SC/IFCI-CHM/24-25/4</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>IFCI Ltd </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee", color: "1" == "1" ? "red" : 'green', fontWeight: "500" }}>
                                                                        {"1" == "1" ? "L1 OL -Price High" : "In-Active"}
                                                                    </TableCell>

                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>



                </Grid>
            </TabPanel >

        </>
    )
}