import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { it } from "date-fns/locale";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import { Type } from "typescript";

interface Data {

    id: number;
    name: string;
    fdate: Date;
    tdate: Date;
    enqcount: number;
    enqvalue: number;
    teamid: string;
    isactive: string;
    cby: string;
    con: Date;
    mby: string;
    mon: Date;
}
const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];



interface Team {
    id: number;
    name: string;
}




function Target() {
    const [data, setData] = useState<Data[]>([]);
    const [team, setTeam] = useState<Team[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formData, setFormData] = useState<any>(
        {

            id: '',
            name: '',
            fdate: " ",
            tdate: '',
            enqcount: '',
            enqvalue: '',
            teamid: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''

        }
    );
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);






    useEffect(() => {
        apiService.getTarget(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
            setData(response.data);

            // response.data?.tableData
            // setTableRows([{
            //   cusid: 'sadd',
            //   name: 'sad',
            // },{
            //   cusid: 'das',
            //   name: 'dsa',
            // }])
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getDropdown("crm_mas_team").then(response => {
            setTeam(response.data);
        }).catch((err) => {
            console.log("err", err)
        });



        //   apiService.getDropdown("mas_dept").then(response => {
        //     setDept(response.data);
        //   }).catch((err) => {
        //     console.log("err", err)
        //   });

        //   apiService.getDropdown("mas_design").then(response => {
        //     setDesign(response.data);
        //   }).catch((err) => {
        //     console.log("err", err)
        //   });

        //   apiService.getDropdown("mas_plant").then(response => {
        //     setPlant(response.data);
        //   }).catch((err) => {
        //     console.log("err", err)
        //   });
    }, [rowsPerPage, page, formData]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    const handleChange = (event: any) => {
        const { name, value } = event.target;





        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            name: '',
            fdate: '',
            tdate: '',
            enqcount: '',
            enqvalue: '',
            teamid: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''

        });
        setSelectedItem(null);


    };




    // Insert and update
    let submit = (event: any) => {
        event.preventDefault();
        console.log("inside")
        // Insert
        if (selectedItem == null) {
            // let data = {
            //   tableRows1: formData?.tableRows,
            //   code: formData?.code,
            //   typeid: formData?.typeid,
            //   sname: formData?.sname,
            //   name: formData?.name,
            //   cityid: formData?.cityid,
            //   cperson: formData?.cperson,
            //   mobile: formData?.mobile,
            //   email: formData?.email,
            //   gstno: formData?.gstno,
            //   pan: formData?.pan,
            //   tinno: formData?.tinno,
            //   isactive: formData?.isactive,
            //   cby: "1",
            //   isexe: formData?.isexe,
            //   cusid: formData?.cusid,
            //   cusref: formData?.cusref,
            //   cuspocname: formData?.cuspocname,
            //   cuspocno: formData?.cuspocno,
            //   cusdesign: formData?.cusdesign,
            //   ismajor: formData?.ismajor,
            //   add1: formData?.add1,
            //   add2: formData?.add2,
            //   add3: formData?.add3,
            //   add4: formData?.add4,
            //   add5: formData?.add5,
            //   cadd1: formData?.cadd1,
            //   cadd2: formData?.cadd2,
            //   cadd3: formData?.cadd3,
            //   cadd4: formData?.cadd4,
            //   cadd5: formData?.cadd5,
            //   ceo: formData?.ceo,
            //   add6: formData?.add6,
            //   add7: formData?.add7,
            //   cadd6: formData?.cadd6,
            //   cadd7: formData?.cadd7
            // };


            let data = {
                name: formData?.name,
                fdate: formData?.fdate,
                tdate: formData?.tdate,
                enqcount: formData?.enqcount,
                enqvalue: formData?.enqvalue,
                teamid: formData?.teamid,
                isactive: formData?.isactive,
                cby: "1",
                mby: formData?.mby

            }

            console.log("data", data);
            apiService.insertTarget(data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                } else {
                    successToast(response.data);
                    console.log("response.data", response.data);

                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        name: '',
                        fdate: '',
                        tdate: '',
                        enqcount: '',
                        enqvalue: '',
                        teamid: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: ''
                    });
                    handleClose();

                }
            }).catch((err) => {
                console.log("err", err);
            });

        }

        // Update
        else {
            let data = {
                name: formData?.name,
                fdate: formData?.fdate,
                tdate: formData?.tdate,
                enqcount: formData?.enqcount,
                enqvalue: formData?.enqvalue,
                teamid: formData?.teamid,
                isactive: formData?.isactive,
                cby: "1",
                mby: " "
            };


            apiService.updateTarget(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                } else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        name: '',
                        fdate: '',
                        tdate: '',
                        enqcount: '',
                        enqvalue: '',
                        teamid: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err);
            });
        }
    };
    const handleEditClick = (item: Data) => {
        // let isa = item?.isactive == "true"? 1 : 0;


        setFormData({
            id: item?.id.toString(),
            name: item?.name,
            fdate: item?.fdate,
            tdate: item?.tdate,
            enqcount: item?.enqcount,
            enqvalue: item?.enqvalue,
            teamid: item?.teamid,
            isactive: item?.isactive,
            cby: item?.cby,
            con: item?.con,
            mby: item?.mby,
            mon: item?.mon
        });
        setSelectedItem(item);
        setOpen(true);
    };

    const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;



    };




    return (
        <>
            <Helmet>
                <title>Target - CRM</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Target"
                    subHeading=""
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // alignItems: 'flex-end',
                        mt: -3.5, // Add some margin-bottom for spacing
                    }}
                >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Add New
                    </Button></Box>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                            Add New
                        </Button>
                    </Grid> */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                        <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                {/* Employee Info Section */}
                                <Grid item xs={12} >

                                </Grid>


                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        name='name'
                                        value={formData?.name}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="fdate"
                                        label="From Date"
                                        name='fdate'
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={formData?.fdate.split('T')[0] || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="tdate"
                                        label="To Date"
                                        name='tdate'
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={formData?.tdate.split('T')[0] || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="enqcount"
                                        label="EnquiryCount"
                                        name='enqcount'
                                        value={formData?.enqcount}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="enqvalue"
                                        label="EnquiryValue"
                                        name='enqvalue'
                                        value={formData?.enqvalue}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        select
                                        label="Team"
                                        name='teamid'
                                        value={formData?.teamid}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"

                                    >
                                        {team.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        select
                                        margin="dense"
                                        label="Status"
                                        name='isactive'
                                        fullWidth
                                        value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                                        onChange={handleChange}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={submit} variant='contained' color="primary" >
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>

                        </DialogActions>
                    </Dialog>
                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>From Date</TableCell>
                                            <TableCell>To Date</TableCell>
                                            <TableCell>Enquiry Count</TableCell>
                                            <TableCell>Team</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                            {/* Add more headers as necessary */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice().map((row: any, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell>{row?.name}</TableCell>
                                                <TableCell>{row.fdate}</TableCell>
                                                <TableCell>{row.tdate}</TableCell>
                                                <TableCell>{row.enqcount}</TableCell>
                                                <TableCell>{row.teamid}</TableCell>
                                                <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                                                    {row.isactive == "1" ? "Active" : "In-Active"}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                                                </TableCell>
                                                {/* Add more cells as necessary */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={data.length === 0 ? 0 : data[0]["count"]}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />
        </>
    );
}

export default Target;
function setType(data: any) {
    throw new Error("Function not implemented.");
}

